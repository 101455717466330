import React, {useState} from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import axios from "axios";
import AddRecordModal from "./AddRecordModal";
import {errHandler} from "../utils";
import {faSearch, faXmark} from '@fortawesome/free-solid-svg-icons'
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, InputGroup, Row, Button, Spinner} from "react-bootstrap";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import './AdminPanel.css'
import Fuse from 'fuse.js'


const ConfigTable = props => {
    const accessToken = localStorage.getItem("accessToken");
    const [selected, setSelected] = useState(undefined)
    const [addingRecord, setAddingRecord] = useState(false)
    const [newRecord, setNewRecord] = useState(undefined)
    const [filterText, setFilterText] = useState("")


    const handleOnSelect = (row, isSelect) => {
        setSelected(row.id)
    }

    const deleteRecord = () => {
        if (!selected) {
            alert("Select a record first.")
        } else if (window.confirm('Are you sure you want to delete this record?')) {
            axios.post(props.deleteUrl,
                {id: selected},
                {headers: {'x-access-token': accessToken}}
            ).then(r => {
                props.setData(props.data.filter(x => x.id !== selected))
            }).catch(errHandler)
         }
    }

    const addRecord = () => {
        axios.post(
            props.addUrl,
            newRecord,
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setAddingRecord(false)
            props.setData([...props.data, res.data])
        }).catch(err => {
            errHandler(err)
        })
    }

    const handleClose = () => {
        setAddingRecord(false)
    }

    const selectRow = {
        mode: 'radio',
        onSelect: handleOnSelect,
        clickToSelect: true,
        clickToEdit: true  // Click to edit cell also
    };

    const beforeSaveCell = (url, oldValue, newValue, row, column, done) => {
        if (window.confirm('Do you want to update this record?')) {
            if (oldValue !== undefined) done() // Limitation by react-bootstrap-table2
            axios.post(
                url,
                { id: row.id, changeParams: {
                        [column.dataField]: newValue
                    }},
                { headers: { 'x-access-token': accessToken } }
            ).then((res) => {
                console.log("Successfully changed record")
            }).catch((err) => {
                errHandler(err);
            })

            if (oldValue === undefined) {
                window.location.reload()
            } // Limitation by react-bootstrap-table2

        } else {
            done(false); // reject the changes
            return { async: true };
        }
    }

    const afterSaveCell = (url, oldValue, newValue, row, column) => {
        // console.log(oldValue)
        // console.log(newValue)
    }

    const filterOnText = (text, data) => {
        if (text === "" || !data.length) {
            return data
        }

        // To enable all keys
        // const options = {
        //     keys: data.length ? Object.keys(data[0]) : []
        // }

        const options = {
            keys: props.columns.map(x => x.dataField)
        }

        const fuse = new Fuse(data, options)
        return fuse.search(text).map(x => x.item)
    }


    return !props.data ?
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        :
        <div className="config-table">
            <div className="config-table-scroll">
                <Row className="config-table-filters">
                    <Col className="mb-3 filter flex-row w-100 justify-content-around ml-10" xs="auto">
                        <AddRecordModal fields={props.columns}
                                        newRecord={ newRecord }
                                        setNewRecord={ setNewRecord }
                                        show={addingRecord}
                                        handleClose={handleClose}
                                        handleSave={addRecord} />
                        {props.disableEdit ? null
                            : <><div className="config-table-edit-buttons">
                                <Button variant="success" disabled={props.disableEdit ?? false} className="crud-button add-record-button" onClick={() => setAddingRecord(true)}>+ Add record</Button>
                                <Button variant="danger" disabled={props.disableEdit ?? false} className="crud-button delete-record-button" onClick={deleteRecord}>&minus; Delete record</Button>
                            </div>
                            <div className="d-inline text-muted font-weight-light font-italic">Double click to edit a record, press enter to save.</div></>
                        }

                        <div className="filter-input">
                            <div className="d-flex align-items-center">
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                    <Form.Control placeholder="Search..." value={filterText} onChange={(e) => setFilterText(e.target.value)} required type="text" name="Text" />
                                </InputGroup>
                                <FontAwesomeIcon icon={faXmark} className={"delete-filter-icon " + (filterText === "" ? 'disabled' : '')} onClick={() => setFilterText("")}/>
                            </div>
                        </div>

                    </Col>
                </Row>

                <BootstrapTable
                    bodyStyle={ {width: 500, maxWidth: 500, wordBreak: 'break-all' } }
                    pagination={paginationFactory({ sizePerPage: 10, hideSizePerPage: true, hidePageListOnlyOnePage: true})}
                    keyField="id"
                    data={ filterOnText(filterText, props.data) }
                    columns={ props.columns }
                    selectRow={ selectRow }
                    bordered={ false }
                    cellEdit={ props.disableEdit ? false : cellEditFactory({
                        mode: 'click',
                        beforeSaveCell: (oldValue, newValue, row, column, done) => beforeSaveCell(props.updateUrl, oldValue, newValue, row, column, done),
                        afterSaveCell: (oldValue, newValue, row, column) => afterSaveCell(props.updateUrl, oldValue, newValue, row, column)
                    }) }
                />
            </div>
        </div>
};

ConfigTable.propTypes = {

};

export default ConfigTable;