import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ConfirmationModal(props) {
    let startTime = new Date(props.startTime).toLocaleDateString() + ", " + new Date(props.startTime).toLocaleTimeString()
    let endTime = new Date(props.endTime).toLocaleTimeString()



    return (
        <Modal show={props.show} onHide={() => props.handleClose(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm reservation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Do you want to confirm the following reservation:
                <p>{startTime} - {endTime}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.handleClose(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => props.handleClose(true)}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationModal;