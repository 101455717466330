import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col} from "react-bootstrap";

import {clearLocalStorage} from "../utils";

const SlotConfirmed = props => {

    let tempAccount, startTime, endTime;
    if (!props.cancelled) {
        tempAccount = localStorage.getItem("temporary") === "true";
        startTime = new Date(props.confirmedSlot.start_time).toLocaleDateString() + ", " + new Date(props.confirmedSlot.start_time).toLocaleTimeString()
        endTime = new Date(props.confirmedSlot.end_time).toLocaleTimeString()
    }


    return (
        <Col>

        {!!props.cancelled ? <>
            <h4 className="text-center">Cancelled reservation</h4>
            <p className="text-center">Your reservation has been cancelled.</p>
            </>
           : <>
                <p className="text-center">Your slot has been reserved on {startTime} - {endTime}</p>
                <p className="text-center">We sent you an email containing the details of your slot.</p>
                <div className="text-center">
                    {
                        tempAccount ?
                            <Button variant="primary" href='/login' onClick={() => clearLocalStorage()} className="text-center">Logout ></Button>
                            : <Button variant="primary" href='/select-cargo' className="text-center">New reservation ></Button>
                    }
                </div>
            </>
        }
        </Col>
    );
};

SlotConfirmed.propTypes = {

};

export default SlotConfirmed;