import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import UsersConfig from "./UsersConfig";
import ConfigTable from "./ConfigTable";
import {errHandler} from "../utils";
import {Type} from "react-bootstrap-table2-editor";

const TransportTypeConfig = props => {
    const [transportTypes, setTransportTypes] = useState(undefined)

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");

        // User
        axios.get(
            process.env.REACT_APP_HOST + '/api/cargo/list_types',
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setTransportTypes(res.data)
        }).catch(errHandler)
    }, [])

    const columns = [{
        dataField: 'id',
        text: 'ID',
        type: 'hide',
        headerStyle: (column, colIndex) => {
            return { width: '70px' };
        }
    },{
        dataField: 'code',
        text: 'Code',
        type: 'text'
    },{
        dataField: 'description',
        text: 'Description',
        type: 'text'
    },{
        dataField: 'icon_name',
        text: 'Icon',
        type: 'dropdown',
        editor: {
            type: Type.SELECT,
            options: [{
                'label': 'Truck',
                'value': 'truck'
            },{
                'label': 'Fast Truck',
                'value': 'truck-fast'
            },{
                'label': 'Truck Loading',
                'value': 'truck-loading'
            },{
                'label': 'Car',
                'value': 'car'
            },{
                'label': 'Van',
                'value': 'van-shuttle'
            },{
                'label': 'Pickup Truck',
                'value': 'truck-pickup'
            },{
                'label': 'Truck Arrow Right',
                'value': 'truck-arrow-right'
            }]
        }
    }]

    return <ConfigTable
        columns={columns}
        data={transportTypes}
        setData={setTransportTypes}
        addUrl={process.env.REACT_APP_HOST + '/api/cargo/add_transport_type'}
        updateUrl={process.env.REACT_APP_HOST + '/api/cargo/update_transport_type'}
        deleteUrl={process.env.REACT_APP_HOST + '/api/cargo/remove_transport_type'}
    />
};

TransportTypeConfig.propTypes = {

};

export default TransportTypeConfig;