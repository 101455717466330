// React wrapper
import React from 'react';
import  DesktopSlotSelection from './DesktopSlotSelection';
import TabletSlotSelection from './TabletSlotSelection';


export default function SlotSelection({ ...props }) {
    // const layout = localStorage.getItem("layout");
    const layout = "tablet"

    if (layout === 'tablet') {
        return <TabletSlotSelection {...props} />
    } else {
        return <DesktopSlotSelection {...props} />
    }
}