import React, {useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import axios from "axios";
import {Spinner} from "react-bootstrap";

import ConfigTable from "./ConfigTable";
import {errHandler} from "../utils";
import {Type} from "react-bootstrap-table2-editor";


// TODO: Add role change option
const UsersConfig = props => {
    const [users, setUsers] = useState(undefined)
    const [roles, setRoles] = useState(undefined)

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");

        // User
        axios.get(
            process.env.REACT_APP_HOST + '/api/user/list',
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            let rawData = res.data
            rawData = rawData.map(x => ({...x, roleId: x.roles[0].id }))
            setUsers(rawData)
        }).catch(errHandler)

        axios.get(
            process.env.REACT_APP_HOST + '/api/user/get_roles',
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setRoles(res.data)
        }).catch(errHandler)
    }, [])

    let columns = [{}]
    if (roles && users) {
        let roleOptions = roles.map(x => ({
            'value': x.id,
            'label': x.name
        }))
         columns = [{
             dataField: 'id',
             text: 'ID',
             type: 'hide',
             headerStyle: (column, colIndex) => {
                 return {width: '70px'};
             }

         }, {
             dataField: 'username',
             text: 'Username',
             type: 'text',
             headerStyle: (column, colIndex) => {
                 return {width: '250px'};
             }
         }, {
             dataField: 'email',
             text: 'Email',
             type: 'email',
             headerStyle: (column, colIndex) => {
                 return {width: '250px'};
             }
         }, {
             dataField: 'erp_code',
             text: 'Client Nr',
             type: 'text',
             headerStyle: (column, colIndex) => {
                 return {width: '150px'};
             }
         }, {
             dataField: 'active',
             text: 'Active',
             type: 'dropdown',
             editor: {
                 type: Type.SELECT,
                 options: [{
                     'label': 'true',
                     'value': 'true'
                 }, {
                     'label': 'false',
                     'value': 'false'
                 }]
             },
             headerStyle: (column, colIndex) => {
                 return {width: '100px'};
             }
         }, {
             dataField: 'verified',
             text: 'Verified',
             type: 'dropdown',
             editor: {
                 type: Type.SELECT,
                 options: [{
                     'label': 'true',
                     'value': 'true'
                 }, {
                     'label': 'false',
                     'value': 'false'
                 }]
             },
             headerStyle: (column, colIndex) => {
                 return {width: '100px'};
             }
         }, {
             dataField: 'roleId',
             text: 'Role',
             type: 'dropdown',
             editor: {
                 type: Type.SELECT,
                 options: roleOptions
             }
         }]
    }

    return <ConfigTable
        columns={columns}
        data={users}
        setData={setUsers}
        addUrl={process.env.REACT_APP_HOST + '/api/user/add'}
        updateUrl={process.env.REACT_APP_HOST + '/api/user/update_other'}
        deleteUrl={process.env.REACT_APP_HOST + '/api/user/remove'}
    />
};

UsersConfig.propTypes = {
    
};

export default UsersConfig;