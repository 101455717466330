import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import axios from 'axios'
import {Navigate, useNavigate} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {Col, NavDropdown, Row} from "react-bootstrap";

import "./LoginForm.css";


const LoginScreen = props => {
    // TODO: This module should be seperated into ClientLogin, TranporterLogin, Signup, ForgotPassword

    const {register, handleSubmit, errors} = useForm();
    const [signingUp, setSigningUp] = useState(false)
    const [accessToken, setAccessToken] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [forgotPassword, setForgotPassword] = useState(false)
    const [resettedPassword, setResettedPassowrd] = useState(false)
    const [loginWithIdentifier, setLoginWithIdentifier] = useState(false)
    let that = this


    function onSubmit(e) {
        setErrorMessage('')
        if (forgotPassword) {
            let email = e.target.elements.email.value
            axios.post(process.env.REACT_APP_HOST + '/api/auth/reset_password', {
                email: email
            })
            .then(function (response) {
                setResettedPassowrd(true)
            })
            .catch(function (error) {
                setErrorMessage(error.response.data.message)
            });
        } else if (signingUp) {
            let username = e.target.elements.username.value
            let password = e.target.elements.password.value
            let email = e.target.elements.email.value

            axios.post(process.env.REACT_APP_HOST + '/api/auth/signup', {
                username: username,
                email: email,
                password: password
            })
                .then(function (response) {
                    localStorage.setItem('accessToken', response.data.accessToken)
                    setAccessToken(response.data.accessToken)
                })
                .catch(function (error) {
                    setErrorMessage(error.response.data.message)
                });
        } else if (e.target.elements.username?.value || !loginWithIdentifier) {
            let username = e.target.elements.username.value
            let password = e.target.elements.password.value
            axios.post(process.env.REACT_APP_HOST + '/api/auth/signin', {
                username: username,
                password: password
            })
                .then(function (response) {
                    localStorage.setItem('accessToken', response.data.accessToken)
                    setAccessToken(response.data.accessToken)
                    localStorage.setItem('temporary', false)
                })
                .catch(function (error) {
                    setErrorMessage(error.response.data.message)
                });
        } else {
            let clientNumber = e.target.elements.clientNumber.value
            let reference = e.target.elements.reference.value
            axios.post(process.env.REACT_APP_HOST + '/api/auth/signin_with_identifier', {
                clientNumber: clientNumber,
                identifier: reference
            })
                .then(function (response) {
                    localStorage.setItem('accessToken', response.data.accessToken)
                    localStorage.setItem('login_identifier', reference)
                    localStorage.setItem('login_clientNumber', clientNumber) // Can also be client_no
                    localStorage.setItem('login_goodsCode', response.data.goods.code) // Can also be client_no
                    localStorage.setItem('login_goodsId', response.data.goods.id)
                    localStorage.setItem('temporary', true)
                    setAccessToken(response.data.accessToken)
                })
                .catch(function (error) {
                    setErrorMessage(error.response.data.message)
                });
        }

        e.preventDefault();
    }

    // Check if accesstoken already exists
    if (localStorage.getItem("accessToken") && !accessToken) { setAccessToken(localStorage.getItem("accessToken")) }
    if (accessToken) {
        return <Navigate to="/home"/>
    }

    let signInScreen = (
            <div className="login-screen-container">
                <form className="login-screen" onSubmit={onSubmit}>
                    <h3 className="login-screen-title">Sign In</h3>
                    <Row>
                        <Col>
                            <div className="login-screen-content">
                                <p className="login-screen-second-title">Transporter</p>
                                <div className="form-group mt-3">
                                    <label>Order reference</label>
                                    <input
                                        type="text"
                                        name="reference"
                                        className="form-control mt-1"
                                        placeholder="Enter order reference"
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <label>Client number</label>
                                    <input
                                        type="text"
                                        name="clientNumber"
                                        className="form-control mt-1"
                                        placeholder="Enter client number"
                                    />
                                </div>
                                <div className="d-grid gap-2 mt-3">
                                    <button type="submit" className="btn btn-primary" onClick={() => setLoginWithIdentifier(true)}>
                                        Sign in as 
                                        <br></br>
                                        Transporter
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="login-screen-content">
                                <p className="login-screen-second-title">Client</p>
                                <div className="form-group mt-3">
                                    <label>Username</label>
                                    <input
                                        type="text"
                                        name="username"
                                        className="form-control mt-1"
                                        placeholder="Enter username"
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control mt-1"
                                        placeholder="Enter password"
                                    />
                                </div>
                                <div className="d-grid gap-2 mt-3">
                                    <button type="submit" className="btn btn-primary" onClick={() => setLoginWithIdentifier(false)}>
                                        Sign in as 
                                        <br></br>
                                        Client
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {errorMessage ? <div className="alert alert-danger mt-3 mb-3 m-4" role="alert">{errorMessage}</div> : null }

                    <div className="text-center mt-4">
                        Not registered yet?{" "}
                        <span className="link-primary" onClick={() => setSigningUp(true)}>
                                        Sign Up
                                      </span>
                    </div>
                    <p className="text-center mt-2">
                        <span className="link-primary" onClick={() => setForgotPassword(true)}>Forgot password?</span>
                    </p>
                </form>
            </div>
        )

    let signUpScreen = (
        <div className="login-screen-container">
            <form className="login-screen" onSubmit={onSubmit}>
                <div className="login-screen-content">
                    <h3 className="login-screen-title">Sign In</h3>
                    <div className="text-center">
                        Already registered?{" "}
                        <span className="link-primary" onClick={() => setSigningUp(false)}>
                          Sign In
                        </span>
                    </div>
                    <div className="form-group mt-3">
                        <label>Username</label>
                        <input
                            type="text"
                            name="username"
                            className="form-control mt-1"
                            placeholder="Your username"
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Email address</label>
                        <input
                            type="email"
                            name="email"
                            className="form-control mt-1"
                            placeholder="Email Address"
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Password</label>
                        <input
                            type="password"
                            name="password"
                            className="form-control mt-1"
                            placeholder="Password"
                        />
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                        {errorMessage ? <div className="alert alert-danger" role="alert">{errorMessage}</div> : null }
                    </div>
                    <p className="text-center mt-2">
                        <span className="link-primary" onClick={() => setForgotPassword(true)}>Forgot password?</span>
                    </p>
                </div>
            </form>
        </div>
    )

    let forgotPasswordScreen = (
        <div className="login-screen-container">
            <form className="login-screen" onSubmit={onSubmit}>
                <div className="login-screen-content">
                    <h3 className="login-screen-title">Reset password</h3>
                    <div className="form-group mt-3">
                        <label>Email address</label>
                        <input
                            type="email"
                            name="email"
                            className="form-control mt-1"
                            placeholder="Email Address"
                        />
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                        {errorMessage ? <div className="alert alert-danger" role="alert">{errorMessage}</div> : null }
                    </div>
                    <p className="text-center mt-2">
                        <span className="link-primary" onClick={() => setForgotPassword(false)}>Go back to login</span>
                    </p>
                </div>
            </form>
        </div>
    )

    let resettedPasswordScreen = (
        <div className="login-screen-container">
            <form className="login-screen">
                <div className="login-screen-content">
                    <h3 className="login-screen-title">Password reset</h3>
                    <p className="text-center mt-2">
                        Your password was successfully reset. Check your email for the new password.
                    </p>
                </div>
            </form>
        </div>
    )

    let content;
    if (resettedPassword) {
        content = resettedPasswordScreen
    } else if (forgotPassword) {
        content = forgotPasswordScreen
    } else if (!signingUp) {
        content = signInScreen
    } else {
        content = signUpScreen
    }

    return (
        <>
            <Navbar variant='dark' className='login-navbar'>
                <Container>
                    <Navbar.Brand href='/login'>
                        <img
                            alt=""
                            src="/logofrigo.png"
                            height="60px"
                            // className="d-inline-block align-top"
                            className="login-logo"
                        />{' '}
                        {/* Hier staat de title */}
                    </Navbar.Brand>
                </Container>
            </Navbar>
            {content}
        </>
    )
};

export default LoginScreen;