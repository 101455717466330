import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import UsersConfig from "./UsersConfig";
import ConfigTable from "./ConfigTable";
import {errHandler} from "../utils";
import TimeEditor from "./TimeEditor";
import {Type} from "react-bootstrap-table2-editor";

function TableHeader(props) {
    return null;
}

TableHeader.propTypes = {};
const WarehouseConfig = props => {
    const [warehouses, setWarehouses] = useState(undefined)

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");

        // User
        axios.get(
            process.env.REACT_APP_HOST + '/api/warehouse/list',
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setWarehouses(res.data)
        }).catch(errHandler)
    }, [])

    const DayOfWeekEditor = props => {
        const { value, onUpdate, ...rest } = props;
        const [selected, setSelected] = useState(value)

        let handleSelect = (e) => {
            let val = parseInt(e.target.value)
            if (selected.includes(val)) {
                setSelected(selected.filter(x => x !== val))
            } else {
                setSelected([...selected, val])
            }
        }

        return (
            <div>
                <div>
                    <input className="m-1" checked={selected.includes(1)} onClick={handleSelect} type="checkbox" value={1} />
                    <label>Mon</label>
                </div>
                <div>
                    <input className="m-1" checked={selected.includes(2)} onClick={handleSelect} type="checkbox" value={2} />
                    <label>Tue</label>
                </div>
                <div>
                    <input className="m-1" checked={selected.includes(3)} onClick={handleSelect} type="checkbox" value={3} />
                    <label>Wed</label>
                </div>
                <div>
                    <input className="m-1" checked={selected.includes(4)} onClick={handleSelect} type="checkbox" value={4} />
                    <label>Thu</label>
                </div>
                <div>
                    <input className="m-1" checked={selected.includes(5)} onClick={handleSelect} type="checkbox" value={5} />
                    <label>Fri</label>
                </div>
                <div>
                    <input className="m-1" checked={selected.includes(6)} onClick={handleSelect} type="checkbox" value={5} />
                    <label>Sat</label>
                </div>
                <div>
                    <input className="m-1" checked={selected.includes(7)} onClick={handleSelect} type="checkbox" value={5} />
                    <label>Sun</label>
                </div>
                <button
                    key="submit"
                    className="btn btn-primary m-1"
                    onClick={ () => onUpdate(selected) }
                >
                    Save
                </button>
            </div>
        )
    }
    let dateString = new Date().toISOString().substring(0, 11)
    const columns = [{
        dataField: 'id',
        text: 'ID',
        type: 'hide',
        headerStyle: (column, colIndex) => {
            return { width: '50px' };
        }
    },
    // ,{
    //     dataField: 'adminId',
    //     text: 'adminID',
    //     type: 'hide',
    //     headerStyle: (column, colIndex) => {
    //         return { width: '70px' };
    //     }
    // },
        {
        dataField: 'code',
        text: 'Code',
        type: 'text',
        headerStyle: (column, colIndex) => {
            return { width: '130px' };
        }
    },{
        dataField: 'description',
        text: 'Description',
        type: 'text',
        headerStyle: (column, colIndex) => {
            return { width: '130px' };
        }
    },{
        dataField: 'opening_time',
        text: 'Opening time',
        type: 'time',
        formatter: (cell, row) => (<>{new Date(dateString + cell).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</>),
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => <TimeEditor {...editorProps} value={value} />,
        headerStyle: (column, colIndex) => {
                    return { width: '100px' };
        }
    },{
        dataField: 'closing_time',
        text: 'Closing time',
        type: 'time',
        formatter: (cell, row) => (<>{new Date(dateString + cell).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</>),
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => <TimeEditor {...editorProps} value={value} />,
        headerStyle: (column, colIndex) => {
            return { width: '100px' };
        }
    },{
        dataField: 'ultimate_reservation_time',
        text: 'Reservation deadline (day before)',
        type: 'time',
        formatter: (cell, row) => (<>{cell == null ? "Not active" : new Date(dateString + cell).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</>),
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => <TimeEditor {...editorProps} value={value} allowNull={true} />,
        headerStyle: (column, colIndex) => {
            return { width: '130px' };
        }
    },
    {
        dataField: 'open_days',
        text: 'Open days (in day numbers)',
        type: 'daysOfWeek',
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
            <DayOfWeekEditor { ...editorProps } value={ value } />
        )
    },{
        dataField: 'slot_time',
        text: 'Slot time (in min.)',
        type: 'number'
    },{
        dataField: 'default_setup_time',
        text: 'Default processing time (in min.)',
        type: 'number'
    },{
        dataField: 'erp_code',
        text: 'ERP Code',
        type: 'text',
        headerStyle: (column, colIndex) => {
            return { width: '130px' };
        }
    },{
        dataField: 'is_default',
        text: 'Default',
        type: 'dropdown',
        editor: {
            type: Type.SELECT,
            options: [{
                'label': 'true',
                'value': 'true'
            },{
                'label': 'false',
                'value': 'false'
            }]
        }
    }]

    return <ConfigTable
        columns={columns}
        data={warehouses}
        setData={setWarehouses}
        addUrl={process.env.REACT_APP_HOST + '/api/warehouse/add'}
        updateUrl={process.env.REACT_APP_HOST + '/api/warehouse/update'}
        deleteUrl={process.env.REACT_APP_HOST + '/api/warehouse/remove'}
    />
};

WarehouseConfig.propTypes = {
    
};

export default WarehouseConfig;