import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';

import {Outlet, Link} from "react-router-dom";
import axios from "axios";

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {NavDropdown} from "react-bootstrap";

import {clearLocalStorage} from "../utils"

import './Layout.css'
import AccountUnauthorized from "../Login/AccountUnauthorized";

const Layout = props => {
    const [userDetails, setUserDetails] = useState("")
    const [accessToken, setAccessToken] = useState(undefined)
    const [errorMessage, setErrorMessage] = useState(undefined)
    const tempAccount = localStorage.getItem("temporary") === 'true';

    let tempAccountName = ""
    if (tempAccount) {
        tempAccountName = localStorage.getItem("login_clientNumber") + " / " + localStorage.getItem("login_identifier")
    }


    useEffect(() => {
        if (props.coldLogin) return

        setErrorMessage("")
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            setAccessToken(accessToken);
        }

        // User
        axios.get(
            process.env.REACT_APP_HOST + '/api/user/get',
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setUserDetails(res.data)
        }).catch((err) => {
            if (err.response?.status === 403) {
                setUserDetails(err.response.data.user)
                setErrorMessage(err.response.data.message)
            } else {
                alert(err)
            }
        })
    }, [])

    let settingsOption = null
    if (userDetails?.roles) {
        settingsOption = userDetails.roles.map(x => x.name).includes("admin")
            || userDetails.roles.map(x => x.name).includes("superuser")
            || userDetails.roles.map(x => x.name).includes("client")
                ? <Nav.Link className="my-2" href="adminpanel">
                    Settings
                </Nav.Link>
                : null
    }

    const logout = () => {
        clearLocalStorage()
    }

    return (
        <div className="logged-in-page">
            <Navbar expand={false} variant='dark' className='navbar'>
                <Container>
                    <Navbar.Brand href="/home">
                        <img
                            alt=""
                            src="/logofrigo.png"
                            height="60"
                            // className="d-inline-block align-top"
                            className='layout-logo'
                        />{' '}
                        {/* Leeuw & Co - Frontend */}
                    </Navbar.Brand>
                    <Nav className="flex-row align-middle">
                        { !props.coldLogin ?
                            <>
                                <Navbar.Text className='mx-2'>
                                    Signed in as: <a href="#">{!tempAccount ? userDetails.username : tempAccountName}</a>
                                </Navbar.Text>
                                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`} />
                            </>
                            : null
                        }
                    </Nav>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-false`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-false`}
                        placement="end"
                    >
                        <Offcanvas.Header className = 'header-bar' closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
                                {!tempAccount ? userDetails.username : tempAccountName}
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav.Link className="my-2" href="home">
                                Home
                            </Nav.Link>
                            {!tempAccount ? <>
                                <Nav.Link className="my-2" href="account">
                                    Account details
                                </Nav.Link>
                                {settingsOption}
                                <hr />
                            </> : null}

                            <Nav.Link className="my-2" onClick={logout} href="login">
                                Logout
                            </Nav.Link>

                            {/*<Navbar.Collapse className="justify-content-end">*/}
                            {/*<NavDropdown style={{textDecoration: 'underline', color: 'white'}}*/}
                            {/*             title={!tempAccount ? userDetails.username : tempAccountName}*/}
                            {/*             id="navbarScrollingDropdown" align={'end'}>*/}


                            {/*</NavDropdown>*/}
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

            <Container className='page-content'>
                { !errorMessage
                    ? <Outlet context={[userDetails, setUserDetails]}/>
                    : <AccountUnauthorized errorMessage={errorMessage}/>
                }
            </Container>


            <Navbar variant='dark' className='footer' >
                <Container className="justify-content-center">
                    <Navbar.Brand>
                        <img
                            alt=""
                            src="/logofrigo.png"
                            height="80px"
                            className="d-inline-block align-top"
                        />{' '}
                    </Navbar.Brand>
                    <div>
                        <h6 className='footer-tc-white'>+31 (0)76 - 593 7030</h6>
                        <h6 className='footer-tc-white'>info@frigobreda.com</h6>    
                    </div>
                </Container>
            </Navbar>

            {/*<Container fluid className='footer'>*/}
            {/*    Footer*/}
            {/*</Container>*/}
        </div>
    )
};

export default Layout;
