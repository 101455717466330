import React from 'react';
import PropTypes from 'prop-types';

const AccountUnauthorized = props => {
    return (
        <div className="verify-account-screen">
            <h3 className="page-title">Account (not yet) verified</h3>
            <div className="page-content-container">
                <div className="alert alert-primary" role="alert">{props.errorMessage}</div>
            </div>
        </div>
    );
};

AccountUnauthorized.propTypes = {

};

export default AccountUnauthorized;