export const errHandler = (err) => {
    if (err.response?.data?.message) {
        alert(err.response.data.message)
    } else {
        alert(err.message)
    }
}

export const clearLocalStorage = () => {
    localStorage.removeItem("accessToken")
    localStorage.removeItem("login_goodsId")
    localStorage.removeItem("login_identifier")
    localStorage.removeItem("temporary")
}

export const validateEmail = (input) => {
    // Check if the input is a valid email address
    const re = /\S+@\S+\.\S+/
    return re.test(input)
}