import React, {useState} from 'react';
import PropTypes from 'prop-types';
import TransporterSelectCargo from "./CargoSelectionTransporter/TransporterSelectCargoWrapper";
import {useOutletContext} from "react-router-dom";
import DesktopSlotSelection from "./SlotSelection/DesktopSlotSelection";
import ConfirmSlot from "./ConfirmSlot";
import SlotConfirmed from "./SlotConfirmed";
import ClientSelectCargo from "./CargoSelectionClient/ClientSelectCargoWrapper";
import {Spinner} from "react-bootstrap";
import SlotSelection from "./SlotSelection/SlotSelectionWrapper";

const CargoSetupScreen = props => {
    const [cargo, setCargo] = useState(undefined)
    const [selectedGoods, setSelectedGoods] = useState([])
    const [bookingSlot, setBookingSlot] = useState(false)
    const [userDetails, setUserDetails] = useOutletContext()

    const [confirmingSlotReservation, setConfirmingSlotReservation] = useState(undefined)
    const [possibleSlots, setPossibleSlots] = useState(undefined)

    const [confirmedSlot, setConfirmedSlot] = useState(undefined)

    let content = <Spinner className="d-flex justify-content-center" animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
    </Spinner>

    if (confirmedSlot) {
        content = <SlotConfirmed confirmedSlot={confirmedSlot} />
    }
    else if (confirmingSlotReservation) {
        content = <ConfirmSlot  possibleSlots={possibleSlots}
                                cargo={cargo}
                                setConfirmingSlotReservation={setConfirmingSlotReservation}
                                setConfirmedTimeslot={setConfirmedSlot} />

    } else if (bookingSlot) {
        content = <SlotSelection cargo={cargo}
                                 goback={() => setBookingSlot(false)}
                                        setConfirmedTimeslot={setConfirmedSlot}
                                        setPossibleSlots={setPossibleSlots}
                                        setConfirmingSlotReservation={setConfirmingSlotReservation}
                                        selectedGoods={selectedGoods}
                                        setBookingSlot={setBookingSlot} />
    } else if (userDetails.verified) {
        if (userDetails.roles.map(x => x.name).includes("client") || userDetails.roles.map(x => x.name).includes("superuser")) {
            content = <ClientSelectCargo
                setBookingSlot={setBookingSlot}
                cargo={cargo} setCargo={setCargo}
                selectedGoods={selectedGoods} setSelectedGoods={setSelectedGoods} />
        } else if (userDetails.roles.map(x => x.name).includes("transporter")) {
            content = <TransporterSelectCargo
                setBookingSlot={setBookingSlot}
                cargo={cargo} setCargo={setCargo}
                selectedGoods={selectedGoods} setSelectedGoods={setSelectedGoods} />
        } else {
            content = <div className="alert alert-primary text-center" role="alert">Log in as a transporter or client to go through the cargo reservation </div>
        }
    }

    return (
        <div>
            <div className="selectcargo-transporter-screen">
                <h3 className="page-title">Cargo reservation</h3>
                <div className="page-content-container-unfilled">
                    {content}
                </div>
            </div>
        </div>
    );
};

CargoSetupScreen.propTypes = {

};

export default CargoSetupScreen;