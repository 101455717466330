import React from 'react';
import TransporterSelectCargoDesktop from './TransporterSelectCargoDesktop';
import TransporterSelectCargoTablet from './TransporterSelectCargoTablet';

export default function TransporterSelectCargo({ ...props }) {
    // const layout = localStorage.getItem("layout");
    const layout = "tablet"

    if (layout === 'tablet') {
        return <TransporterSelectCargoTablet {...props} />
    } else {
        return <TransporterSelectCargoDesktop {...props} />
    }
}