import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import {errHandler} from "../../utils";
import {selectableKeys} from "react-bootstrap-table-next/lib/src/store/selection";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as Icon from 'react-bootstrap-icons';
import {Col, Row} from "react-bootstrap";
import Fuse from "fuse.js";
import {logDOM} from "@testing-library/react";

const { SearchBar } = Search;

const ClientSelectCargoTablet = props => {
    const accessToken = localStorage.getItem("accessToken");

    const [goods, setGoods] = useState([])
    // const [selected, setSelected] = useState(props.selectedGoods.map(x => x.id))
    const [data, setData] = useState([{}])
    const [lastAddedGoods, setLastAddedGoods] = useState([])
    const [scroller, setScroller] = useState(0)
    const [filteredGoods, setFilteredGoods] = useState([])
    const [searchText, setSearchText] = useState('')

    // For scrolling
    const itemsRef = useRef([]);
    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, filteredGoods.length);
    }, [filteredGoods]);

    // Searching
    useEffect(() => {
        const options = {
            keys: ['id', 'description', 'code', 'params.identifier', 'params.erp_code']
        }
        // Map params object to surface object

        let searchedGoods = goods

        if (searchText !== '') {
            const fuse = new Fuse(searchedGoods, options)
            searchedGoods = fuse.search(searchText).map(x => x.item)
        }
        setFilteredGoods(searchedGoods)
    }, [searchText, goods])

    useEffect(() => {
        axios.get(
            process.env.REACT_APP_HOST + '/api/cargo/list_free_goods',
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setGoods(res.data.filter(x => x.active))
            setData(res.data.filter(x => x.active))
        }).catch(errHandler)
    }, [])

    let setCargo = () => {
        let res = axios.post(
            process.env.REACT_APP_HOST + '/api/cargo/add',
            { goodsIds: props.selectedGoods.map(x => x.id) },
            { headers: { 'x-access-token': accessToken } }
        ).then(res => {
            props.setCargo(res.data)
            props.setBookingSlot(true)
        }).catch(errHandler)
    }

    return (
        <Row>
            <Col>
                <div className="order-selected side-controller">
                    <div className="go-up-button selector-button" onClick={
                        () => {
                            if (scroller >= 6) {
                                itemsRef.current[scroller - 6].scrollIntoView({ behavior: 'smooth', block: 'start' })
                                setScroller(scroller - 6)
                            }
                    }}>
                        <Icon.ChevronUp/>
                    </div>
                    <div className="order-selected-counter selector-indicator">
                        <div className="small-text">
                            Orders
                        </div>
                        <div className="big-number">
                            {goods.length}
                        </div>
                    </div>
                    <div className="go-down-button selector-button" onClick={
                        () => {
                            if (scroller <= filteredGoods.length - 6) {
                                itemsRef.current[scroller + 6].scrollIntoView({ behavior: 'smooth', block: 'start' })
                                setScroller(scroller + 6)
                            }
                        }}>
                        <Icon.ChevronDown/>
                    </div>

                </div>
            </Col>
            <Col>
                <div className="goods-selector goods-selector-client">
                    <div>
                        <div className="goods-selector-searchbar">
                            <input className="form-control mr-sm-2" type="search" onChange={(e) => {
                                setScroller(0)
                                setSearchText(e.target.value)
                            }} placeholder="Search" aria-label="Search" />
                        </div>
                        <hr />
                        <div className="order-selection-grid-client order-selection-grid">
                            { filteredGoods.length ? (
                                filteredGoods.map((good, i) => (
                                    <div key={i}
                                         ref={el => itemsRef.current[i] = el}
                                         className={"order-selection-item" + (props.selectedGoods.map(x => x.id).includes(good.id) ? " selected" : "")}
                                         onClick={() => {
                                             if (props.selectedGoods.map(x => x.id).includes(good.id)) {
                                                 props.setSelectedGoods(props.selectedGoods.filter(x => x.id !== good.id))
                                                 setLastAddedGoods(lastAddedGoods.filter(id => id !== good.id))
                                             } else {
                                                 props.setSelectedGoods([...props.selectedGoods, good])
                                                 setLastAddedGoods([...lastAddedGoods, good.id])
                                             }
                                         }}>
                                        <div>
                                            <Row>
                                                <div className="order_block_title_1">
                                                    {good.code}
                                                </div>
                                            </Row>    
                                            <Row className="order_block_title_2">
                                                {good.params.identifier}
                                            </Row>
                                            <Row className="order_block_title_2">
                                                {good.params.erp_code}
                                            </Row>
                                        </div>
                                    </div>
                                ))) : <div className="alert alert-primary text-center w-100" role="alert">No goods found.</div>}
                        </div>
                    </div>
                </div>
            </Col>
            <Col>
                <div className="order-selected side-controller">
                    <div className="go-back-button selector-button red-button" onClick={() => {
                        if (lastAddedGoods.length > 0) {
                            props.setSelectedGoods(props.selectedGoods.filter(x => x.id !== lastAddedGoods[lastAddedGoods.length - 1]))
                            setLastAddedGoods(lastAddedGoods.slice(0, lastAddedGoods.length - 1))
                        }
                    }}>
                        <Icon.ArrowCounterclockwise/>
                    </div>
                    <div className="order-selected-counter selector-indicator">
                        <div className="small-text">
                            Selected
                        </div>
                        <div className="big-number">
                            {props.selectedGoods.length}
                        </div>
                    </div>
                    <div className={"go-next-button selector-button green-button" + (props.selectedGoods.length === 0 ? " selector-disabled" : "")} onClick={() => {
                        if (props.selectedGoods.length > 0) { setCargo() }
                    }}>
                        <Icon.CheckLg/>
                    </div>

                </div>
            </Col>
        </Row>
    );
};

ClientSelectCargoTablet.propTypes = {

};

export default ClientSelectCargoTablet;