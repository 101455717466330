import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import CustomEmailModal from "./CustomEmailModal";
import {Alert, Col, Row} from "react-bootstrap";
import ConfirmationModal from "./ConfirmationModal";
import moment from "moment";
import axios from "axios";
import {errHandler} from "../../utils";



const SelectTimeslot = props => {
    const accessToken = localStorage.getItem("accessToken");
    const tempAccount = localStorage.getItem("temporary") === "true";

    let now = new Date();
    let onejan = new Date(now.getFullYear(), 0, 1);
    // let currentWeek = Math.ceil((((now.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
    let currentWeek = moment().week()
    let currentYear = moment().year()

    const [selectedWeek, setSelectedWeek] = useState(currentWeek)
    const [selectedYear, setSelectedYear] = useState(currentYear)
    const [availableTimes, setAvailableTimes] = useState({})
    const [selectedDate, setSelectedDate] = useState(undefined)


    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [selectedSlotStartTime, setSelectedSlotStartTime] = useState(undefined)
    const [selectedSlotLocationId, setSelectedSlotLocationId] = useState(undefined)

    const [customEmail, setCustomEmail] = useState(false)

    /**
     * Function that set the week, and updates year when going over or under 52 weeks
     * @param week
     */
    let setWeek = (week) => {
        if (week > 52) {
            setSelectedWeek(1)
            setSelectedYear(selectedYear + 1)
        } else if (week < 1) {
            setSelectedWeek(52)
            setSelectedYear(selectedYear - 1)
        } else {
            setSelectedWeek(week)
        }
    }

    let getAvailableTimes = () => {
        // Get first day of week by week number
        // let currentDate = moment().week(selectedWeek).startOf('isoWeek').toISOString()

        let payload = {
            date: moment().year(selectedYear).week(selectedWeek),
            warehouseId: props.warehouseId,
            cargoId: props.cargoId,
            timeSlotDuration: props.processingTime,
            replanning: !!props.replanning
        }

        if (props.replanning) {
            payload.identifier = props.identifier
        }

        axios.post(
            process.env.REACT_APP_HOST + '/api/timeslot/get_possible_timeslots_week',
            payload,
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setAvailableTimes(res.data)
        }).catch(errHandler)
    }

    useEffect(() => {
        getAvailableTimes()
    }, [selectedWeek])

    return <>
        { (tempAccount && !props.replanning) ? <CustomEmailModal setCustomEmail={setCustomEmail} /> : null }
        <Col>
            {!props.replanning && <button className="btn btn-primary position-absolute" onClick={() => props.goback()} style={{marginLeft: 14}}>&lt;</button>}
            <div className="week-selector side-controller">
                <div className="add-week-button selector-button" onClick={() => setWeek(selectedWeek + 1)}>&#43;</div>
                <div className="week-string selector-indicator">
                    <div className="week-label small-text">Week</div>
                    <div className="week-number big-number">{selectedWeek}</div>
                </div>
                <div className={"remove-week-button selector-button" + ((currentWeek === selectedWeek && selectedYear === currentYear) ? " selector-disabled" : "")} onClick={() => {
                    if (currentWeek < selectedWeek || selectedYear > currentYear) setWeek(selectedWeek - 1)
                }}>&minus;</div>
            </div>
        </Col>
        <Col className="dates">
            <div className="dates-title">Select date</div>
            <div>
            { availableTimes.length > 0 ?
                availableTimes.map((x, i) => {
                    let passed = (new Date(x.date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0))
                    return (
                        <div key={i} className={"date-option" + (selectedDate === i ? " button-selected" : "") + (passed ? " selector-disabled" : "")} onClick={() => {
                            if (!passed) setSelectedDate(i)
                        }}>
                            <div className="date-string">{x?.date?.substring(0, 10)}</div>
                            <div className={"date-status date-status-" + x.occupancyStatus}></div>
                        </div>
                    )
                }) : null
            }</div>
        </Col>
        <Col className="times-container">
            <div className="times-title">Select timeslot</div>
            <div className="times">
            {
                (selectedDate !== undefined && availableTimes[selectedDate]) ? availableTimes[selectedDate].startTimes.sort(function(a,b){
                        return new Date(a.startDateTime) - new Date(b.startDateTime);
                    }).map((x, i) => {
                    return (
                        <div className="time-option" onClick={() => {
                            setSelectedSlotStartTime(x.startDateTime)
                            setSelectedSlotLocationId(x.locationId)
                            setShowConfirmationModal(true)
                            // confirmTimeslot(x.startDateTime, x.locationId)
                        }} key={i}>
                            <span className="time-string">{new Date(x.startDateTime).toLocaleTimeString().substring(0,5)}</span>
                        </div>
                    )
                }) : null
            }
            </div>
        </Col>
        <ConfirmationModal show={showConfirmationModal} startTime={selectedSlotStartTime} endTime={moment(selectedSlotStartTime).add(props.processingTime, 'minutes').toISOString()} handleClose={
            (confirmed) => {
                if (confirmed) { props.confirmTimeslot(selectedSlotStartTime, selectedSlotLocationId, customEmail) }
                setShowConfirmationModal(false)
            }
        }/>
    </>
};

SelectTimeslot.propTypes = {

};

export default SelectTimeslot;