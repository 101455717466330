import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import {errHandler} from "../utils";

const ReservationsOverview = props => {
    const accessToken = localStorage.getItem("accessToken");
    const [reservations, setReservations] = useState([])

    useEffect(() => {
        axios.post(
            process.env.REACT_APP_HOST + '/api/timeslot/list',
            {},
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setReservations(res.data)
        }).catch(errHandler)
    }, [])

    let reservationsList;
    if (reservations.length === 0) {
        reservationsList = <tr>
            <td className="text-muted" colSpan="5">No reservations added to this account.</td>
        </tr>
    } else {
        reservationsList = reservations.map(x => (
            <tr key={x.id}>
                <td>{x.id}</td>
                <td>{new Date(x.start_time).toLocaleTimeString()}</td>
                <td>{new Date(x.end_time).toLocaleTimeString()}</td>
                <td>{x.params.cargoId}</td>
                <td>{x.params.locationId}</td>
            </tr> ))
    }

    return (
        <div className="reservations-overview-screen">
            <h3 className="page-title">Reservations Overview</h3>
            <div className="page-content-container">
                <table className="table reservations-table">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Cargo ID</th>
                        <th>Location ID</th>
                    </tr>
                    </thead>
                    <tbody>
                    { reservationsList }
                    </tbody>
                </table>
                <p className="text-center mt-4"><a href="/select-cargo">Go back</a></p>
            </div>
        </div>
    );
};

ReservationsOverview.propTypes = {

};

export default ReservationsOverview;