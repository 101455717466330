import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';

import '../CargoSetup.css'
import axios from "axios";
import {errHandler} from "../../utils";
import {ArrowCounterclockwise} from "react-bootstrap-icons";

const TransporterSelectCargoTablet = props => {
    const accessToken = localStorage.getItem("accessToken");
    const [identifier, setIdentifier] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [goods, setGoods] = useState([])
    const [goodsId, setGoodsId] = useState('')
    const [goodsCode, setGoodsCode] = useState('')
    const [lastAddedGoods, setLastAddedGoods] = useState([])

    let addCargo = (goodsCode, uniqueIdentifier) => {
        setErrorMessage(undefined)

        let res = axios.post(
            process.env.REACT_APP_HOST + '/api/cargo/add',
            {},
            { headers: { 'x-access-token': accessToken } }
        ).then(res => {
            props.setCargo(res.data)
            axios.post(
                process.env.REACT_APP_HOST + '/api/cargo/add_goods_to_cargo_with_identifier',
                { cargoId: res.data.id, clientNumber: goodsCode, identifier: uniqueIdentifier},
                { headers: { 'x-access-token': accessToken } }
            ).then(res => {
                props.setCargo(res.data.cargo)
                props.setSelectedGoods([...props.selectedGoods, res.data.goods])
            }).catch(err => {
                if (err.response?.data?.message) {
                    setErrorMessage(err.response.data.message)
                } else {
                    setErrorMessage(err.message)
                }
            })
        }).catch(err => {
            if (err.response?.data?.message) {
                setErrorMessage(err.response.data.message)
            } else {
                setErrorMessage(err.message)
            }
        })
    }

    let getGoodsWithIdentifier = (identifier) => {
        axios.post(
            process.env.REACT_APP_HOST + '/api/cargo/list_goods_with_identifier',
            { identifier: identifier },
            { headers: { 'x-access-token': accessToken } }
        ).then(res => {
            setGoods(res.data)
        }).catch(err => {
            if (err.response?.data?.message) {
                setErrorMessage(err.response.data.message)
            } else {
                setErrorMessage(err.message)
            }
        })
    }

    let addGoodsToCargo = (e) => {
        setErrorMessage(undefined)
        setIdentifier('')
        setGoodsCode('')

        e.preventDefault()
        let goodsCode = e.target.elements.goodsCode.value
        let uniqueIdentifier = e.target.elements.uniqueIdentifier.value

        if (!props.cargo) {
            addCargo(goodsCode, uniqueIdentifier)
        } else {
            axios.post(
                process.env.REACT_APP_HOST + '/api/cargo/add_goods_to_cargo_with_identifier',
                { cargoId: props.cargo.id, clientNumber: goodsCode, identifier: uniqueIdentifier},
                { headers: { 'x-access-token': accessToken } }
            ).then(res => {
                props.setSelectedGoods([...props.selectedGoods, res.data.goods])
                setLastAddedGoods([...lastAddedGoods, res.data.goods.id])
            }).catch(err => {
                if (err.response?.data?.message) {
                    setErrorMessage(err.response.data.message)
                } else {
                    setErrorMessage(err.message)
                }
            })
        }
    }

    let removeGoodsFromCargo = (goodsId) => {
        setErrorMessage(undefined)

        if (!props.cargo) {
            alert("No cargo selected")
        } else {
            axios.post(
                process.env.REACT_APP_HOST + '/api/cargo/remove_goods_from_cargo',
                { cargoId: props.cargo.id, goodsId: goodsId },
                { headers: { 'x-access-token': accessToken } }
            ).then(res => {
                props.setSelectedGoods(props.selectedGoods.filter(x => x.id !== parseInt(goodsId)))
                setLastAddedGoods(lastAddedGoods.filter(x => x !== goodsId))
            }).catch(err => {
                if (err.response?.data?.message) {
                    setErrorMessage(err.response.data.message)
                } else {
                    setErrorMessage(err.message)
                }
            })
        }
    }

    useEffect(() => {
        if (localStorage.getItem("login_identifier") !== null
            && localStorage.getItem("login_goodsId") !== null
            && !props.selectedGoods.map(x => x.id).includes(parseInt(localStorage.getItem("login_goodsId")))
        ) {
            const loginIdentfier = localStorage.getItem("login_identifier");
            const loginClientNumber = localStorage.getItem("login_clientNumber");

            // Uncomment if you want the added details to be shown in fields
            // setIdentifier(loginIdentfier)
            // setGoodsId(loginGoodsId)

            addCargo(loginClientNumber, loginIdentfier)
            getGoodsWithIdentifier(loginIdentfier)
        }
    }, [])

    return (
        <Row className="transporter-select-cargo-container">
            <Col className="add-cargo-column col-md-3">
                <form onSubmit={addGoodsToCargo}>
                    <Row className="select-cargo-row mt-3">
                        <p style={{fontWeight: "bold"}}>Order reference</p>
                        <div className="cargo-selection-input form-group">
                            <Icon.Key className="cargo-selection-icon" />
                            <input
                                onChange={(e) => setIdentifier(e.target.value)}
                                value={identifier}
                                type="text"
                                name="uniqueIdentifier"
                                className="form-control"
                                placeholder="Order reference"
                            />
                        </div>
                    </Row>
                    <Row className="select-cargo-row">
                        <p style={{fontWeight: "bold"}}>Client number</p>
                        <div className="cargo-selection-input form-group">
                            <Icon.Truck className="cargo-selection-icon" />
                            <input
                                autoFocus
                                onChange={(e) => setGoodsCode(e.target.value)}
                                value={goodsCode}
                                type="text"
                                name="goodsCode"
                                className="form-control"
                                placeholder="Client number"
                            />
                        </div>
                    </Row>
                    <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-primary">
                            Add to cargo
                        </button>
                        {errorMessage ?
                            <div className="alert alert-danger" role="alert">{errorMessage}</div> : null}
                    </div>
                </form>
            </Col>
            <Col className="goods-selector goods-selector-tablet-col">
                <div className="goods-selector goods-selector-tablet order-selection-grid-transporter order-selection-grid" >
                    {props.selectedGoods.map((good, index) => (
                        <div className={"order-selection-item" + (props.selectedGoods.map(x => x.id).includes(good.id) ? " selected" : "")}
                             onClick={() => {
                                 removeGoodsFromCargo(good.id)
                             }} key={index}>
                            <div>
                                <Row>
                                    <div className="order_block_title_1">
                                        {good.code}
                                    </div>
                                </Row>    
                                <Row>
                                    <div className="order_block_title_2">
                                        {good.params.identifier}
                                    </div> 
                                </Row>
                                <Row>
                                    <div className="order_block_title_3">
                                        {good.params.clientNumber}
                                    </div>
                                </Row>
                            </div>
                        </div>
                    ))}
                </div>
            </Col>
            <Col className="col-md-2">
                <div className="order-selected side-controller">
                    <div className="go-back-button selector-button red-button" onClick={() => {
                        if (lastAddedGoods.length > 0) {
                            removeGoodsFromCargo(lastAddedGoods[lastAddedGoods.length - 1])
                            setLastAddedGoods(lastAddedGoods.slice(0, lastAddedGoods.length - 1))
                        }
                    }}>
                        <Icon.ArrowCounterclockwise/>
                    </div>
                    <div className="order-selected-counter selector-indicator">
                        <div className="small-text">
                            Selected
                        </div>
                        <div className="big-number">
                            {props.selectedGoods.length}
                        </div>
                    </div>
                    <div className={"go-next-button selector-button green-button" + (props.selectedGoods.length === 0 ? " selector-disabled" : "")}  onClick={() => {
                        props.selectedGoods.length && props.setBookingSlot(true)
                    }}>
                        <Icon.CheckLg/>
                    </div>

                </div>
            </Col>
        </Row>
    );
};

TransporterSelectCargoTablet.propTypes = {
    
};

export default TransporterSelectCargoTablet;