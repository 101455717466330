import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import {Modal} from "react-bootstrap";
import {validateEmail} from "../../utils";


const CustomEmailModal = (props) => {
    const [show, setShow] = useState(true);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const handleClose = () => {
        if (validateEmail(email)) {
            setShow(false);
            props.setCustomEmail(email)
        } else {
            setError("Invalid email address")
        }

    }
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    In order to confirm your reservation, we need your email address.
                    <input
                        type="email"
                        name="email"
                        className="form-control mt-1"
                        placeholder="Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                        autoFocus
                    />
                    {!!error.length && <div className="alert alert-danger mt-2" role="alert">
                        {error}
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>Continue</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CustomEmailModal;