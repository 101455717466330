import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import {errHandler} from "../utils";

const GoodsOverview = props => {
    const accessToken = localStorage.getItem("accessToken");
    const [goods, setGoods] = useState([])

    useEffect(() => {
        axios.get(
            process.env.REACT_APP_HOST + '/api/cargo/list_goods',
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setGoods(res.data)
        }).catch(errHandler)
    }, [])

    let goodsList;
    if (goods.length === 0) {
        goodsList = <tr>
            <td className="text-muted" colSpan="3">No goods added to this account.</td>
        </tr>
    } else {
        goodsList = goods.map(good => (
            <tr key={good.id}>
                <td>{good.id}</td>
                <td>{good.code}</td>
                <td>{good.description}</td>
            </tr> ))
    }

    return (
        <div className="goods-overview-screen">
            <h3 className="page-title">Goods Overview</h3>
            <div className="page-content-container">
            <table className="table goods-table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Code</th>
                    <th>Description</th>
                </tr>
                </thead>
                <tbody>
                { goodsList }
                </tbody>
            </table>
            </div>
        </div>
    );
};

GoodsOverview.propTypes = {

};

export default GoodsOverview;