import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from "react-bootstrap/Button";
import axios from "axios";
import {errHandler} from "../utils";

const ConfirmSlot = props => {
    const accessToken = localStorage.getItem("accessToken");
    const [selectedSlot, setSelectedSlot] = useState(undefined)
    const [errorMessage, setErrorMessage] = useState('')

    let confirmSlot = () => {
        let confirmedSlot = selectedSlot
        if (props.possibleSlots.length === 1) {
            confirmedSlot = props.possibleSlots[0]
        }
        axios.post(process.env.REACT_APP_HOST + '/api/timeslot/confirm',
            {
                cargoId: props.cargo.id,
                timeSlotId: confirmedSlot.id
            },
            { headers: { 'x-access-token': accessToken } }
        ).then(res => {
            props.setConfirmedTimeslot(res.data)
        }).catch(err => setErrorMessage(err.response.data.message));
    }

    // TODO: Add dock description in table here
    let slots;
    if (props.possibleSlots.length === 0) {
        slots = <tr>
            <td className="text-muted" colSpan="4">No slots found on this day, please select a different day.</td>
        </tr>
    } else {
        slots = props.possibleSlots.map(slot => (
            <tr key={slot.id}>
                <td><input checked={props.possibleSlots.length === 1}
                           key={slot.id} value={slot.id}
                           onChange={() => alert("Test")}
                           type="radio" id='regular'
                           name="slotSelection" /></td>
                <td>{new Date(slot.start_time)}</td>
                <td>{new Date(slot.end_time)}</td>
                <td>{slot.params.locationId}</td>
            </tr> ))
    }




    return (
        <div>
            <p>Please confirm your timeslot</p>
            <p className="text-muted">If the exact slot has already been taken, alternative slots are suggested.</p>

            <table className="table selected-goods-table">
                <thead>
                <tr>
                    <th>Confirm</th>
                    <th>Start time</th>
                    <th>End time</th>
                    <th>Dock</th>
                </tr>
                </thead>
                <tbody>
                { slots }
                </tbody>
            </table>
            <Button variant="primary" onClick={() => props.setConfirmingSlotReservation(false)}> Go back to slot selection</Button>{' '}
            <Button disabled={!selectedSlot && props.possibleSlots.length !== 1} variant="primary" type="button" onClick={confirmSlot}>
                Confirm slot
            </Button>
            {errorMessage ? <div className="alert alert-danger" role="alert">{errorMessage}</div> : null }
        </div>
    );
};

ConfirmSlot.propTypes = {

};

export default ConfirmSlot;