// React wrapper
import React from 'react';
import ClientSelectCargoDesktop from "./ClientSelectCargoDesktop";
import ClientSelectCargoTablet from "./ClientSelectCargoTablet";

export default function ClientSelectCargoWrapper({ ...props }) {
    // const layout = localStorage.getItem("layout");
    const layout = "tablet"

    if (layout === 'tablet') {
        return <ClientSelectCargoTablet {...props} />
    } else {
        return <ClientSelectCargoDesktop {...props} />
    }
}