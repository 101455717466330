import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import UsersConfig from "./UsersConfig";
import ConfigTable from "./ConfigTable";
import {errHandler} from "../utils";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import {Col, InputGroup, Row, Spinner} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ExportToCsv } from 'export-to-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch, faXmark} from '@fortawesome/free-solid-svg-icons'

import './AdminPanel.css'


import Fuse from 'fuse.js'

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from "react-bootstrap-table2-paginator";
const { ExportCSVButton } = CSVExport;

const TimeslotConfig = props => {
    const accessToken = localStorage.getItem("accessToken");
    const [timeslots, setTimeslots] = useState(undefined)
    const [selectedCargo, setSelectedCargo] = useState(undefined)
    const [selectedTimeslot, setSelectedTimeslot] = useState(undefined)
    const [filterDate, setFilterDate] = useState(props.selectToday
        ? new Date().toISOString().slice(0, 10)
        : "dd/mm/yyyy")
    const [filterText, setFilterText] = useState("")

    const [warehouses, setWarehouses] = useState([{}])
    const [warehouseId, setWarehouseId] = useState(undefined)

    // Get list of warehouses from server
    useEffect(() => {
        axios.get(
            process.env.REACT_APP_HOST + '/api/warehouse/list'
        ).then((res) => {
            setWarehouses(res.data)

            let defaultId = res.data[0].id
            for (const wh of res.data) {
                if (wh.is_default) {
                    defaultId = wh.id
                }
            }

            if (props.passWarehouseId) { props.passWarehouseId(defaultId) }
            if (props.passWarehouseName) {
                let code = res.data.find(wh => wh.id === defaultId).code
                props.passWarehouseName(code)
            }
            setWarehouseId(defaultId)
        }).catch(errHandler)
    }, [])


    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");

        axios.get(
            process.env.REACT_APP_HOST + '/api/timeslot/list',
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setTimeslots(res.data)
        }).catch(errHandler)
    }, [])

    const onClick = (e, row, rowIndex) => {
        let slotId = row.id
        for (const timeslot of timeslots) {
            if (timeslot.id === slotId) {
                setSelectedTimeslot(timeslot)
                axios.post(
                    process.env.REACT_APP_HOST + '/api/cargo/get',
                    { cargoId: timeslot.params.cargoId },
                    { headers: { 'x-access-token': accessToken } }
                ).then((res) => {
                    setSelectedCargo(res.data)
                }).catch(errHandler)
            }
        }
    }

    const columns = [{
        dataField: 'id',
        text: 'ID',
        type: 'hide'
    },{
        dataField: 'start_time',
        text: 'Start time',
        type: 'hide',
        formatter: (cell, row) => (<>{new Date(cell).toLocaleDateString()}<br />{new Date(cell).toLocaleTimeString()} </>)
    },{
        dataField: 'end_time',
        text: 'End time',
        type: 'hide',
        formatter: (cell, row) => (<>{new Date(cell).toLocaleDateString()}<br />{new Date(cell).toLocaleTimeString()} </>)
    },{
        dataField: 'params.locationId',
        text: 'Location ID',
        type: 'hide'
    },{
        dataField: 'status',
        text: 'Status',
        type: 'hide'
    }]

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Timeslot Overview',
        filename: 'timeslot_overview',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        headers: columns.map(x => x.dataField)
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const updateWarehouseId = (warehouseId) => {
        setWarehouseId(warehouseId)
        if (warehouseId === -1) {
            return
        }

        if (props.passWarehouseId) { props.passWarehouseId(warehouseId) }
        if (props.passWarehouseName) {
            let code = warehouses.find(wh => wh.id === parseInt(warehouseId)).code
            props.passWarehouseName(code)
        }
    }

    const updateFilterDate = (date) => {
        setFilterDate(date)
        if (props.passDate && date !== "dd/mm/yyyy") { props.passDate(date) }
    }


    const filterTimeslots = () => {
        let filteredTimeslots = filterOnWarehouse(warehouseId, timeslots)
        filteredTimeslots = filterOnDate(filterDate, filteredTimeslots)
        if (props.setTimeslotCount) {
            props.setTimeslotCount(filteredTimeslots.length)
        }
        filteredTimeslots = filterOnText(filterText, filteredTimeslots)
        return filteredTimeslots
    }

    const filterOnWarehouse = (warehouseId, timeslots) => {
        if (warehouseId === -1) {
            return timeslots
        }


        return timeslots.filter(ts => parseInt(ts.params.warehouseId) === parseInt(warehouseId))
    }

    const filterOnDate = (date, timeslots) => {
        if (date === "dd/mm/yyyy") {
            return timeslots
        }

        return timeslots.filter(ts => (new Date(ts.start_time).setHours(0,0,0,0)
            === new Date(date).setHours(0,0,0,0)))
    }

    const filterOnText = (text, timeslots) => {
        if (text === "") {
            return timeslots
        }

        const options = {
            keys: ['id','params.cargoId', 'params.locationId', 'status']
        }
        const fuse = new Fuse(timeslots, options)
        return fuse.search(text).map(x => x.item)
    }

    const exportToCSV = () => {
        let csvData = filterTimeslots(timeslots).map(ts => ({
            id: ts.id,
            start_time: new Date(ts.start_time).toLocaleDateString() + " " + new Date(ts.start_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
            end_time: new Date(ts.end_time).toLocaleDateString() + " " + new Date(ts.end_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
            cargoId: ts.params.cargoId,
            locationId: ts.params.locationId
        }))
        if (csvData.length === 0) {
            csvData = [{
                id: "No data",
                start_time: "No data",
                end_time: "No data",
                cargoId: "No data",
                locationId: "No data"
            }]
        }

        csvExporter.generateCsv(csvData);
    }

    let cargoModal = selectedCargo ? <Modal show={!!selectedCargo} onHide={() => setSelectedCargo(undefined)}>
        <Modal.Header closeButton>
            <Modal.Title>View cargo details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="cargo-modal">
                <div>Cargo ID: {selectedCargo.id}</div>
                <div>Transporter Email: {selectedTimeslot.params?.email ? selectedTimeslot.params.email : '-'}</div>
                <div><a href={window.location.origin + '/change-timeslot?identifier=' + selectedTimeslot.params.identifier}>Change this reservation</a></div>
            </div>

            <table className="table selected-goods-table">
                <thead>
                <tr>
                    <th>Reference</th>
                    <th>Description</th>
                    <th>Identifier</th>
                    <th>Client Nr</th>
                </tr>
                </thead>
                <tbody>
                {!selectedCargo.goods.length
                    ? <tr className="text-muted">
                        <td>No goods selected...</td>
                        <td></td>
                    </tr>
                    : selectedCargo.goods.map(x => (
                        <tr>
                            <td>{x?.code ? x.code : '-'}</td>
                            <td>{x?.description ? x.description : '-'}</td>
                            <td>{x?.params?.identifier ? x.params.identifier : '-'}</td>
                            <td>{x?.params?.erp_code ? x.params.erp_code : '-'}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setSelectedCargo(undefined)}>
                Close
            </Button>
        </Modal.Footer>
    </Modal> : null

    return !timeslots ?
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        : <div className="config-table-scroll">
                <Row className="timeslot-filters">
                    <Col className="mb-3 filter" xs="auto">
                        {/*<div className="filter-text text-muted">Filter on text</div>*/}
                        <div className="filter-input">
                            <InputGroup>
                                <InputGroup.Text id="basic-addon1">
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                                <Form.Control placeholder="Search..." value={filterText} onChange={(e) => setFilterText(e.target.value)} required type="text" name="Text" />
                            </InputGroup>
                        </div>
                        <FontAwesomeIcon icon={faXmark} className={"delete-filter-icon " + (filterText === "" ? 'disabled' : '')} onClick={() => setFilterText("")}/>
                    </Col>
                    <Col className="mb-3 filter" xs="auto">
                        {/*<div className="filter-text text-muted">Date</div>*/}
                        <div className="filter-input">
                            <Form.Control value={filterDate} onChange={(e) => updateFilterDate(e.target.value)} required type="date" name='Date' />
                        </div>
                        <FontAwesomeIcon icon={faXmark} className={"delete-filter-icon " + (filterDate === 'dd/mm/yyyy' ? 'disabled' : '')} onClick={() => updateFilterDate('dd/mm/yyyy')}/>
                    </Col>
                    <Col className="mb-3 filter" xs="auto">
                        {/*<div className="filter-text text-muted">Date</div>*/}
                        <div className="filter-input">
                            <Form.Select required name='warehouse' value={warehouseId} onChange={(e) => updateWarehouseId(e.target.value)}>
                                <option disabled value={-1}>Not specified</option>

                                { warehouses.map(type => (
                                    <option value={type.id}>{type.code} ({type.description})</option>
                                ))}
                            </Form.Select>
                        </div>
                        <FontAwesomeIcon icon={faXmark} className={"delete-filter-icon " + (warehouseId === -1 ? 'disabled' : '')} onClick={() => updateWarehouseId(-1)}/>
                    </Col>

                    <Col className="mb-3 filter" xs="auto">
                        <Button variant="primary" onClick={() => exportToCSV()} className="export-csv">Export to CSV</Button>
                    </Col>
                </Row>

                <BootstrapTable
                    rowEvents={ {onClick} }
                    pagination={paginationFactory({ sizePerPage: 10, hideSizePerPage: true, hidePageListOnlyOnePage: true})}
                    bodyStyle={ {width: 500, maxWidth: 500, wordBreak: 'break-all' } }
                    keyField="id"
                    data={ filterTimeslots(timeslots) }
                    columns={ columns }
                    bordered={ false }
                />
                { cargoModal }
            </div>
};

TimeslotConfig.propTypes = {

};

export default TimeslotConfig;