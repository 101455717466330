import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSearchParams} from "react-router-dom";
import SelectTimeslot from "./SlotSelection/SelectTimeslot";
import {Alert, Row, Spinner} from "react-bootstrap";
import axios from "axios";
import {errHandler} from "../utils";
import moment from "moment";
import './CargoSetup.css'
import Button from "react-bootstrap/Button";
import SlotConfirmed from "./SlotConfirmed";

const ChangeTimeslot = props => {
    // get arguments from url
    const accessToken = localStorage.getItem("accessToken");

    const [searchParams, setSearchParams] = useSearchParams();
    const [identifier, setIdentifier] = useState(searchParams.get("identifier"));
    const [loading, setLoading] = useState(true);
    const [cargo, setCargo] = useState(null);
    const [timeslot, setTimeslot] = useState(null);
    const [user, setUser] = useState(null);
    const [warehouseId, setWarehouseId] = useState(null);
    const [processingTime, setProcessingTime] = useState(null);
    const [reservationNotFound, setReservationNotFound] = useState(true);
    const [confirmedTimeslot, setConfirmedTimeslot] = useState(null);
    const [screenState, setScreenState] = useState("loading");


    let changeTimeslot = (selectedSlotStartTime, selectedSlotLocationId, customEmail) => {
        let startTime = selectedSlotStartTime
        let dockId = selectedSlotLocationId

        // Add processing time to start time date format using momentjs
        let endDate = moment(startTime).add(processingTime, 'minutes').toISOString()

        // alert("Timeslot confirmed! " + startTime + " - " + endDate)

        let data = {
            dockId: timeslot.params.locationId,
            cargoId: cargo.id,
            startDateTime: startTime,
            endDateTime: endDate,
            identifier: identifier,
            email: timeslot.params.email
        }

        axios.post(
            process.env.REACT_APP_HOST + '/api/timeslot/change_timeslot',
            data,
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setConfirmedTimeslot(res.data)
            setScreenState("slotConfirmed")
        }).catch(errHandler)
    }

    useEffect(() => {
        // Fetch data using identifier
        axios.post(
            process.env.REACT_APP_HOST + '/api/timeslot/get_timeslot_info_by_identifier',
            {
                identifier: identifier
            }).then((res) => {
                let { warehouseId, cargo, timeslot, user } = res.data
                setWarehouseId(warehouseId)
                setCargo(cargo)
                setTimeslot(timeslot)
                setUser(user)
                setProcessingTime(moment(timeslot.end_time).diff(moment(timeslot.start_time), 'minutes'))

                // Check if timeslot is expired by end time
                if (moment(timeslot.end_time).isBefore(moment())) {
                    setScreenState("reservationNotFound")
                } else {
                    setScreenState("reservationFound")
                }

                setLoading(false)
            }).catch((err) => {
                setScreenState("reservationNotFound")
                setLoading(false)
            })
    }, []);

    let getOriginalTimeslotMessage = (originalTimeslot) => {
        let startTime = new Date(originalTimeslot.start_time).toLocaleDateString() + ", " + new Date(originalTimeslot.start_time).toLocaleTimeString()
        let endTime = new Date(originalTimeslot.end_time).toLocaleTimeString()

        return "Your original timeslot: " + startTime + " - " + endTime
    }

    let cancelReservation = () => {
        axios.post(
            process.env.REACT_APP_HOST + '/api/timeslot/remove_by_identifier',
            {
                identifier: identifier
            },
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setScreenState("slotCancelled")
        }).catch(errHandler)
    }

    let content = <></>
    switch(screenState) {
        case "loading":
             content = <div className="flex-column text-center">
                <Spinner animation="border" />
                <p className="mt-2">Searching for your reservation... </p>
            </div>
            break;
        case "reservationNotFound":
             content = <div className="flex-column text-center">
                <h4>Reservation not found or expired</h4>
                <p className="mt-2">We could not find a reservation with the identifier you provided. <br /> Please check your email for the correct identifier.</p>
            </div>
            break;
        case "reservationFound":
             content = <>
                <Alert className='original-timeslot-info-text text-center'>{getOriginalTimeslotMessage(timeslot)} <button type="button" className="w-15 justify-content-end btn btn-danger mx-5 btn-sm btn-block" onClick={cancelReservation}>Cancel this reservation</button></Alert>
                <Row>
                    <SelectTimeslot warehouseId={warehouseId}
                                    cargoId={cargo.id}
                                    processingTime={processingTime}
                                    confirmTimeslot={changeTimeslot}
                                    replanning={true}
                                    identifier={identifier}
                                    originalTimeslot={timeslot}
                    />
                </Row>
            </>
            break;
        case "slotConfirmed":
            content = <SlotConfirmed confirmedSlot={confirmedTimeslot} />
            break;
        case "slotCancelled":
            content = <SlotConfirmed cancelled={true} />
            break;
    }


    return (
        <div className="page-content-container-unfilled">
            {content}
        </div>
    );
};

ChangeTimeslot.propTypes = {

};

export default ChangeTimeslot;