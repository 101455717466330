import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import UsersConfig from "./UsersConfig";
import ConfigTable from "./ConfigTable";
import {errHandler} from "../utils";
import {Type} from "react-bootstrap-table2-editor";

const CargoTypeConfig = props => {
    const [cargoTypes, setCargoTypes] = useState(undefined)
    const [transportTypes, setTransportTypes] = useState(undefined)

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");

        // User
        axios.get(
            process.env.REACT_APP_HOST + '/api/cargo/list_types',
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setTransportTypes(res.data.map(x => ({value: x.id, label: x.code})))
            let data = res.data.map(x => x.cargo_types.map(y => ({...y, transportTypeId: x.id, transportTypeLabel: x.code})))
            setCargoTypes(data.flat())
        }).catch(errHandler)
    }, [])

    const columns = [{
        dataField: 'id',
        text: 'ID',
        type: 'hide',
        headerStyle: (column, colIndex) => {
            return { width: '70px' };
        }
    },{
        dataField: 'code',
        text: 'Code',
        type: 'text'
    },{
        dataField: 'description',
        text: 'Description',
        type: 'text'
    },{
        dataField: 'slots_needed',
        text: 'Slots needed',
        type: 'number'
    },{
        dataField: 'transportTypeId',
        text: 'Transport type',
        type: 'dropdown',
        editor: {
            type: Type.SELECT,
            options: transportTypes
        }
    }]

    return <ConfigTable
        columns={columns}
        data={cargoTypes}
        setData={setCargoTypes}
        addUrl={process.env.REACT_APP_HOST + '/api/cargo/add_type'}
        updateUrl={process.env.REACT_APP_HOST + '/api/cargo/update_type'}
        deleteUrl={process.env.REACT_APP_HOST + '/api/cargo/remove_type'}
    />
};

CargoTypeConfig.propTypes = {

};

export default CargoTypeConfig;