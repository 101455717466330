import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {Col} from "react-bootstrap";

const AddRecordModal = props => {
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create record</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { props.fields.map(field => {
                    let onInput = ({target:{value}}) => {
                         if (field.dataField.includes("params.")) {
                            let dataField = field.dataField.replace("params.","")
                            let newParams;
                            if (props.newRecord?.params) {
                                newParams = {
                                    ...props.newRecord.params,
                                    [dataField]: value
                                }
                            } else {
                                newParams = {
                                    [dataField]: value
                                }
                            }
                            props.setNewRecord({...props.newRecord, params: newParams})
                        } else {
                            props.setNewRecord({...props.newRecord, [field.dataField]: value})
                        }
                    }

                    let controlId = ''

                    if (field.type === 'hide') {
                        return;
                    } else if (field.type === 'email') {
                        return (
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>{field.text}</Form.Label>
                                <Form.Control required type="email" onChange={onInput} />
                            </Form.Group>
                        )
                    } else if (field.type === 'dropdown') {
                        return (
                            <Form.Group className="mb-3">
                                <Form.Label>{field.text}</Form.Label>
                                <Form.Select required type={field.type} label={field.text} onChange={onInput}>
                                    <option value='' selected disabled>Select option</option>
                                    { field.editor.options.map(x => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        )
                    } else if (field.type === 'time') {
                        let dateString = new Date().toISOString().substring(0, 11)
                        return (
                            <Form.Group className="mb-3">
                                <Form.Label>{field.text}</Form.Label>
                                <Form.Control required type="time" name='slotTime' onChange={(e) => onInput({
                                    target:
                                        { value: new Date(dateString + e.target.value).toISOString().substring(11, 24) }
                                })} />
                            </Form.Group>
                        )
                    } else if (field.type === 'daysOfWeek') {
                        let handleSelect = (e) => {
                            let val = parseInt(e.target.value)

                            if (!props.newRecord) {
                                props.setNewRecord({[field.dataField]: [val]})
                            } else if (!props.newRecord[field.dataField]) {
                                props.setNewRecord({...props.newRecord, [field.dataField]: [val]})
                            }  else if (props.newRecord[field.dataField].includes(val)) {
                                props.setNewRecord({...props.newRecord, [field.dataField]: props.newRecord[field.dataField].filter(x => x !== val)})
                            } else {
                                props.setNewRecord({...props.newRecord, [field.dataField]: [...props.newRecord[field.dataField], val]})
                            }
                        }

                        return <div className="day-of-week-selector">
                                <div>
                                    <input className="m-1" onClick={handleSelect} type="checkbox" value={1} />
                                    <label>Mon</label>
                                </div>
                                <div>
                                    <input className="m-1" onClick={handleSelect} type="checkbox" value={2} />
                                    <label>Tue</label>
                                </div>
                                <div>
                                    <input className="m-1" onClick={handleSelect} type="checkbox" value={3} />
                                    <label>Wed</label>
                                </div>
                                <div>
                                    <input className="m-1" onClick={handleSelect} type="checkbox" value={4} />
                                    <label>Thu</label>
                                </div>
                                <div>
                                    <input className="m-1" onClick={handleSelect} type="checkbox" value={5} />
                                    <label>Fri</label>
                                </div>
                                <div>
                                    <input className="m-1" onClick={handleSelect} type="checkbox" value={6} />
                                    <label>Sat</label>
                                </div>
                                <div>
                                    <input className="m-1" onClick={handleSelect} type="checkbox" value={7} />
                                    <label>Sun</label>
                                </div>
                            </div>
                    }

                    return (
                        <Form.Group className="mb-3" controlId={controlId}>
                            <Form.Label>{field.text}</Form.Label>
                            <Form.Control type={field.type} onChange={onInput} />
                        </Form.Group>
                    )
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={props.handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

AddRecordModal.propTypes = {
    
};

export default AddRecordModal;