import { Outlet, Navigate } from 'react-router-dom'
import React, {useContext, useEffect, useState} from "react";
import jwt_decode from "jwt-decode";
import {clearLocalStorage} from "../utils"



const PrivateRoutes = () => {
    let accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
        let decoded = jwt_decode(accessToken);
        if (new Date(decoded.exp * 1000) < Date.now()) {
            clearLocalStorage()
            accessToken = undefined
        }
    }

    return accessToken ? <Outlet/> : <Navigate to="/login"/>
}

export default PrivateRoutes