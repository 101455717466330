import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import UsersConfig from "./UsersConfig";
import ConfigTable from "./ConfigTable";
import {errHandler} from "../utils";
import {Type} from "react-bootstrap-table2-editor";
import TimeEditor from "./TimeEditor";

const DockConfig = props => {
    const [Docks, setDocks] = useState(undefined)

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");

        // User
        axios.get(
            process.env.REACT_APP_HOST + '/api/warehouse/list_docks',
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setDocks(res.data)
        }).catch(errHandler)
    }, [])

    let dateString = new Date().toISOString().substring(0, 11)
    const columns = [{
        dataField: 'id',
        text: 'ID',
        type: 'hide',
        headerStyle: (column, colIndex) => {
            return { width: '70px' };
        }
    },{
        dataField: 'params.warehouseId',
        text: 'Warehouse ID',
        type: 'number'
    },{
        dataField: 'code',
        text: 'Code',
        type: 'text'
    },{
        dataField: 'description',
        text: 'Description',
        type: 'text'
    },{
        dataField: 'params.opening_time',
        text: 'Opening time',
        type: 'time',
        formatter: (cell, row) => (<>{new Date(dateString + cell).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</>),
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => <TimeEditor {...editorProps} value={value} />
    },{
        dataField: 'params.closing_time',
        text: 'Closing time',
        type: 'time',
        formatter: (cell, row) => (<>{new Date(dateString + cell).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</>),
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => <TimeEditor {...editorProps} value={value} />
    },{
        dataField: 'active',
        text: 'Active',
        type: 'dropdown',
        editor: {
            type: Type.SELECT,
            options: [{
                'label': 'true',
                'value': 'true'
            },{
                'label': 'false',
                'value': 'false'
            }]
        }
    }]

    return <ConfigTable
        columns={columns}
        data={Docks}
        setData={setDocks}
        addUrl={process.env.REACT_APP_HOST + '/api/warehouse/add_dock'}
        updateUrl={process.env.REACT_APP_HOST + '/api/warehouse/update_dock'}
        deleteUrl={process.env.REACT_APP_HOST + '/api/warehouse/remove_dock'}
    />
};

DockConfig.propTypes = {

};

export default DockConfig;