import React, {useEffect, useState} from 'react';
import axios from "axios";
import UsersConfig from "./UsersConfig";
import WarehouseConfig from "./WarehouseConfig";
import DocksConfig from "./DocksConfig";
import {Tab, Tabs} from "react-bootstrap";

import './AdminPanel.css'
import GoodsConfig from "./GoodsConfig";
import CargoConfig from "./CargoConfig";
import {useOutletContext} from "react-router-dom";
import TimeslotConfig from "./TimeslotConfig";
import CargoTypeConfig from "./CargoTypeConfig";
import TransportTypeConfig from "./TransportTypeConfig";


const AdminPanelScreen = () => {
    const [userDetails, setUserDetails] = useOutletContext()

    let tabs = null
    if (userDetails.roles) {
        if (userDetails.roles.map(x => x.name).includes("admin")) {
            tabs = (
                <Tabs
                    defaultActiveKey="users"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="users" title="Users" className="edit-tab">
                        <UsersConfig />
                    </Tab>
                    <Tab eventKey="warehouses" title="Warehouses" className="edit-tab">
                        <WarehouseConfig />
                    </Tab>
                    <Tab eventKey="docks" title="Docks" className="edit-tab">
                        <DocksConfig />
                    </Tab>
                    <Tab eventKey="goods" title="Orders" className="edit-tab">
                        <GoodsConfig />
                    </Tab>
                    {/*<Tab eventKey="cargos" title="Cargos" className="edit-tab">*/}
                    {/*    <CargoConfig />*/}
                    {/*</Tab>*/}
                    <Tab eventKey="timeslots" title="Reservation" className="edit-tab">
                        <TimeslotConfig />
                    </Tab>
                    <Tab eventKey="transportTypes" title="Transport Types" className="edit-tab">
                        <TransportTypeConfig />
                    </Tab>
                    <Tab eventKey="cargoTypes" title="Cargo Types" className="edit-tab">
                        <CargoTypeConfig />
                    </Tab>
                </Tabs>
            )
        } else if (userDetails.roles.map(x => x.name).includes("client") || userDetails.roles.map(x => x.name).includes("superuser")) {
            tabs = (
                <Tabs
                    defaultActiveKey="goods"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="goods" title="Orders" className="edit-tab">
                        <GoodsConfig />
                    </Tab>
                    {/*<Tab eventKey="cargos" title="Cargos" className="edit-tab">*/}
                    {/*    <CargoConfig />*/}
                    {/*</Tab>*/}
                    <Tab eventKey="timeslots" title="Reservations" className="edit-tab">
                        <TimeslotConfig />
                    </Tab>
                </Tabs>
            )
        }
    }

    return (
        <div>
            <h3 className="page-title">Edit records</h3>
            <div className="edit-tab-container">
                    {tabs}
            </div>
        </div>
    );
};

export default AdminPanelScreen;
