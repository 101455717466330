import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import TimeslotConfig from "../AdminPanel/TimeslotConfig";
import {Col, Row} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import {PolarAngleAxis, RadialBar, RadialBarChart, Tooltip} from "recharts";

import './Overview.css'
import axios from "axios";
import {errHandler} from "../utils";

const TimeslotOverview = props => {
    const accessToken = localStorage.getItem("accessToken");

    const [percentageReserved, setPercentageReserved] = useState(0)
    const [filterDate, setFilterDate] = useState(new Date().toISOString().substring(0, 10))
    const [warehouseId, setWarehouseId] = useState(undefined)
    const [warehouseName, setWarehouseName] = useState(undefined)
    const [workloadInHours, setWorkloadInHours] = useState(0)
    const [data, setData] = useState([])
    const [timeSlotCount, setTimeslotCount] = useState(0)
    const [closedDays, setClosedDays] = useState(false)


    useEffect(x => {
        if (!warehouseId || !filterDate) {
            return
        }

        axios.post(
            process.env.REACT_APP_HOST + '/api/timeslot/capacity_week',
            { warehouseId: warehouseId, date: new Date(filterDate) },
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            let relevantDay = res.data.find(x => x.dayOfWeek === new Date(filterDate).getDay())
            if (relevantDay === undefined) {
                setClosedDays(true)
            } else {
                setClosedDays(false)
                setPercentageReserved(relevantDay.capacity)
                setWorkloadInHours(Math.round((relevantDay.occupiedMinutes / 60) * 100) / 100)
            }
        }).catch(errHandler)
    }, [filterDate, warehouseId])


    return (
        <div>
            <div className="timeslot-overview-screen">
                <h3 className="page-title">Timeslot Overview</h3>
                <div className="page-content-container">
                    <Row className="d-flex justify-content-center mt-4">
                        <Col sm={7} className="timeslot-overview-column timeslot-overview-column-left">
                            <TimeslotConfig passDate={setFilterDate}
                                            passWarehouseId={setWarehouseId}
                                            passWarehouseName={setWarehouseName}
                                            setTimeslotCount={setTimeslotCount}
                                            selectToday={true} />
                        </Col>
                        <Col sm={4} className="timeslot-overview-column timeslot-overview-column-right">
                        {
                            closedDays
                            ? <div>
                                <h4 className='text-center'>Workload - {filterDate}</h4>
                                <hr /><div className="alert alert-primary text-center" role="alert">The warehouse is not open on this day.</div>
                            </div>
                            : <><div>
                                <h4 className='text-center'>Workload - {filterDate}</h4>
                                <hr />
                                <table className="workload-table">
                                    <thead></thead>
                                    <tbody>
                                        <tr>
                                            <td style={{fontWeight: "bold", width: '200px'}}>Total time</td>
                                            <td>{workloadInHours}hr</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: "bold", marginRight: '200px'}}>Total timeslots</td>
                                            <td>{timeSlotCount}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='radial-chart-container'>
                                <RadialBarChart
                                        width={300}
                                        height={250}
                                        innerRadius="50%"
                                        outerRadius="100%"
                                        data={[
                                            { occupancy: percentageReserved, key: 'Occupancy', fill: '#50C878' },
                                        ]}
                                        // barSize={100}
                                        startAngle={210}
                                        endAngle={-30}
                                    >
                                        <PolarAngleAxis type="number" domain={[0, 100]} tick={false} />
                                        <RadialBar label={{ fill: '#50C878' }}
                                                   background clockWise={true} dataKey='occupancy' unit='%' />
                                        {/*<Legend iconSize={10} width={120} height={140} layout='vertical' verticalAlign='middle' align="right" />*/}
                                        <Tooltip />
                                </RadialBarChart>
                                <div className="radial-chart-label">
                                    <h4 className="radial-chart-label-percentage">{percentageReserved}%</h4>
                                    <p className="radial-chart-label-text text-center">available capacity <br/> in {warehouseName} </p>
                                </div>
                            </div></>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
};

TimeslotOverview.propTypes = {
    
};

export default TimeslotOverview;