import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter, Routes, Route, Outlet, useNavigate} from "react-router-dom";
import "./App.css";
import LoginScreen from "./Login/LoginScreen";
import HomeScreen from "./Home/HomeScreen";
import Layout from "./Layout/Layout";

import PrivateRoutes from "./authentication/PrivateRoutes";
import AdminPanelScreen from "./AdminPanel/AdminPanelScreen";
import AccountScreen from "./Account/AccountScreen";
import TransporterSelectCargoDesktop from "./CargoSetup/CargoSelectionTransporter/TransporterSelectCargoDesktop";
import AccountUnauthorized from "./Login/AccountUnauthorized";
import CargoSetupScreen from "./CargoSetup/CargoSetupScreen";
import GoodsOverview from "./Overview/GoodsOverview";
import ReservationsOverview from "./Overview/ReservationsOverview";
import TimeslotOverview from "./Overview/TimeslotOverview";
import ChangeTimeslot from "./CargoSetup/ChangeTimeslot";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PrivateRoutes />}>
                    <Route path="/" element={<Layout />} >
                        <Route path="/home" element={<HomeScreen />} />
                        <Route path="/" element={<HomeScreen />} />
                        <Route path="/account" element={<AccountScreen />} />
                        <Route path="/adminpanel" element={<AdminPanelScreen />} />
                        <Route path="/goods-overview" element={<GoodsOverview />} />
                        <Route path="/reservations-overview" element={<ReservationsOverview />} />
                        <Route path="/select-cargo" default element={<CargoSetupScreen />} />
                        <Route path="/timeslot-overview" default element={<TimeslotOverview />} />
                    </Route>
                </Route>

                <Route path="/" element={<Layout coldLogin={true}/>} >
                    <Route path="/change-timeslot" element={<ChangeTimeslot />} />
                </Route>
                <Route path="/login" element={<LoginScreen />} />
            </Routes>
        </BrowserRouter>
    )
}



