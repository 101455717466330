import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import {errHandler} from "../../utils";
import {selectableKeys} from "react-bootstrap-table-next/lib/src/store/selection";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as Icon from 'react-bootstrap-icons';


const { SearchBar } = Search;

const ClientSelectCargoDesktop = props => {
    const accessToken = localStorage.getItem("accessToken");

    const [goods, setGoods] = useState([])
    const [selected, setSelected] = useState(props.selectedGoods.map(x => x.id))
    const [data, setData] = useState([{}])

    useEffect(() => {
        axios.get(
            process.env.REACT_APP_HOST + '/api/cargo/list_free_goods',
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setGoods(res.data)
            setData(res.data)
        }).catch(errHandler)
    }, [])

    let handleCheck = (row, isSelect) => {
        if (props.selectedGoods.map(x => x.id).includes(row.id)) {
            props.setSelectedGoods(props.selectedGoods.filter(x => x.id !== row.id))
            setSelected(selected.filter(x => x !== row.id))
        } else {
            props.setSelectedGoods([...props.selectedGoods, goods.filter(x => x.id === row.id)[0]])
            setSelected([...selected, row.id])
        }
    }

    let setCargo = () => {
        let res = axios.post(
            process.env.REACT_APP_HOST + '/api/cargo/add',
            { goodsIds: props.selectedGoods.map(x => x.id) },
            { headers: { 'x-access-token': accessToken } }
        ).then(res => {
            props.setBookingSlot(true)
            props.setCargo(res.data)
        }).catch(errHandler)
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: selected,
        onSelect: handleCheck,
        hideSelectAll: true
    };

    const columns = [
        {
            dataField: 'id',
            text: 'ID'
        },{
            dataField: 'code',
            text: 'Code'
        },{
            dataField: 'description',
            text: 'Description'
        }
    ]

    return (
        <div>
            <p className="text-center">Select the goods you would like to add to this cargo</p>
            <div className="goods-selector">
                <ToolkitProvider
                    keyField="id"
                    data={ goods }
                    columns={ columns }
                    bordered={false}
                    search>
                    { props => (
                        <div>
                            <div className="goods-selector-searchbar">
                                {/*<Icon.Search className="search-icon" />*/}
                                <SearchBar { ...props.searchProps } srText='' />
                            </div>
                            <hr />
                            { goods.length ? (
                                <BootstrapTable
                                    selectRow={ selectRow }
                                    { ...props.baseProps }
                                />

                            ) : <div className="alert alert-primary text-center" role="alert">No goods found.</div>}
                        </div>
                    )}
                </ToolkitProvider>
            </div>

            <div className="d-grid gap-2 mt-3">
                <button type="button" disabled={!props.selectedGoods.length} onClick={setCargo} className="btn btn-primary">
                    Book a slot >
                </button>
            </div>
        </div>

    );
};

ClientSelectCargoDesktop.propTypes = {

};

export default ClientSelectCargoDesktop;