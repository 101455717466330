import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import UsersConfig from "./UsersConfig";
import ConfigTable from "./ConfigTable";
import {errHandler} from "../utils";
import {Type} from "react-bootstrap-table2-editor";
import {useOutletContext} from "react-router-dom";


const GoodsConfig = props => {
    const [goods, setGoods] = useState(undefined)
    const [userDetails, setUserDetails] = useOutletContext()


    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");

        // User
        axios.get(
            process.env.REACT_APP_HOST + '/api/cargo/list_goods',
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            setGoods(res.data)
        }).catch(errHandler)
    }, [])

    const columns = [{
        dataField: 'id',
        text: 'ID',
        type: 'hide',
        headerStyle: (column, colIndex) => {
            return { width: '70px' };
        }
    },{
        dataField: 'params.clientId',
        text: 'Client ID',
        type: 'number'
    },{
        dataField: 'code',
        text: 'Order Nr',
        type: 'text'
    },{
        dataField: 'description',
        text: 'Description',
        type: 'text'
    },{
        dataField: 'params.identifier',
        text: 'Client Ref',
        type: 'text'
    },{
        dataField: 'params.erp_code',
        text: 'Client Nr',
        type: 'text'
    },{
        dataField: 'active',
        text: 'Active',
        type: 'dropdown',
        editor: {
            type: Type.SELECT,
            options: [{
                'label': 'true',
                'value': 'true'
            },{
                'label': 'false',
                'value': 'false'
            }]
        }
    }]

    return <ConfigTable
        columns={columns}
        data={goods}
        setData={setGoods}
        // disableEdit={(!userDetails.roles.map(x => x.name).includes("admin"))}
        disableEdit={true}
        addUrl={process.env.REACT_APP_HOST + '/api/cargo/add_goods'}
        updateUrl={process.env.REACT_APP_HOST + '/api/cargo/update_goods'}
        deleteUrl={process.env.REACT_APP_HOST + '/api/cargo/remove_goods'}
    />
};

GoodsConfig.propTypes = {

};

export default GoodsConfig;