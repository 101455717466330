import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useOutletContext } from "react-router-dom";
import './AccountScreen.css'
import axios from "axios";
import {errHandler} from "../utils";


const AccountScreen = props => {
    const accessToken = localStorage.getItem("accessToken");
    const [userDetails, setUserDetails] = useOutletContext()
    const [changingPassword, setChangingPassword] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [changedPassword, setChangedPassword] = useState(false)

    let changePassword = (e) => {
        setChangedPassword(false)
        setErrorMessage('')
        e.preventDefault()
        if (e.target.elements.passwordOne.value !== e.target.elements.passwordTwo.value) {
            setErrorMessage("Passwords are not the same.")
        } else {
            axios.post(
                process.env.REACT_APP_HOST + '/api/user/update',
                {
                    changeParams: {
                        password: e.target.elements.passwordOne.value
                    }
                },
                {headers: {'x-access-token': accessToken}}
            ).then((res) => {
                setChangedPassword(true)
            }).catch(err => {
                setErrorMessage(err.response.data.message)
            })
        }
    }
    let roles = ''
    if (userDetails.roles) {
        roles = userDetails.roles.map(x => x.name).join(", ")
    }
    return (
        <div className="account-screen">
            <h3 className="page-title">Account details</h3>
            <div className="page-content-container">
                <table className="account-table">
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td style={{fontWeight: "bold"}}>Account ID</td>
                            <td>{userDetails.id}</td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: "bold"}}>Username</td>
                            <td>{userDetails.username}</td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: "bold"}}>Email</td>
                            <td>{userDetails.email}</td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: "bold"}}>Roles</td>
                            <td>{roles}</td>
                        </tr>
                    </tbody>
                </table>
                <form className="change-password-form" onSubmit={changePassword}>
                    <p className="text-center">Change your password </p>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Your new password</label>
                        <input type="password" name="passwordOne" className="form-control form-control-sm" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Repeat password</label>
                        <input type="password" name="passwordTwo" className="form-control form-control-sm" />
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-primary">
                            Change password
                        </button>
                        {errorMessage ? <div className="alert alert-danger" role="alert">{errorMessage}</div> : null }
                        {changedPassword ? <div className="alert alert-primary" role="alert">
                            Successfully changed password!
                        </div> : null }
                    </div>
                </form>
            </div>
        </div>
    );
};

AccountScreen.propTypes = {

};

export default AccountScreen;