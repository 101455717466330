import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import Button from "react-bootstrap/Button";
import {useOutletContext} from "react-router-dom";
import TimeslotOverview from "../Overview/TimeslotOverview";
import CargoSetupScreen from "../CargoSetup/CargoSetupScreen";
import GoodsOverview from "../Overview/GoodsOverview";

const HomeScreen = props => {
    const [userDetails, setUserDetails] = useOutletContext()
    if (!userDetails || !userDetails.roles) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }


    if (userDetails.roles.map(x => x.name).includes("admin")) {
        return <TimeslotOverview />
    } else if (userDetails.roles.map(x => x.name).includes("transporter")
        || userDetails.roles.map(x => x.name).includes("client")
        || userDetails.roles.map(x => x.name).includes("superuser")) {
        return <CargoSetupScreen />
    } else {
        return (
            <div className="verify-account-screen">
                <h3 className="page-title">Account unauthorized</h3>
                <div className="page-content-container">
                    <div className="alert alert-primary" role="alert">Please contact the administrator to authorize your account.</div>
                </div>
            </div>
        )

    }
}

HomeScreen.propTypes = {
    
};

export default HomeScreen;