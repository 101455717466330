import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Col, Form, Row, Badge} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {errHandler} from "../../utils";
import ReservationWeekChart from "../ReservationCharts/ReservationWeekChart";
import ReservationDayChart from "../ReservationCharts/ReservationDayChart";
import moment from "moment/moment";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {faTruck, faTruckFast, faTruckLoading, faCar, faVanShuttle, faFontAwesome, faTruckPickup, faTruckArrowRight } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ConfirmationModal from "./ConfirmationModal";
import SelectTimeslot from "./SelectTimeslot";
/* loading svg*/
import laVan from '../../Pictures/van.svg';
import laTruck from '../../Pictures/truck.svg';
import laContainer from '../../Pictures/container.svg';
import laWarehouse from '../../Pictures/warehouse.svg';
library.add(fas, faTruck, faTruckFast, faTruckLoading, faCar, faVanShuttle, faFontAwesome, faTruckPickup, faTruckArrowRight)

const TabletSlotSelection = props => {
    const accessToken = localStorage.getItem("accessToken");
    const tempAccount = localStorage.getItem("temporary") === "true";

    const [cargoTypes, setCargoTypes] = useState([])
    const [cargoType, setCargoType] = useState(undefined)
    const [transportTypes, setTransportTypes] = useState([])
    const [transportType, setTransportType] = useState(undefined)
    const [warehouses, setWarehouses] = useState([])
    const [processingTime, setProcessingTime] = useState(undefined)
    const [warehouseId, setWarehouseId] = useState(undefined)
    const [showTimeCapacityDayGraph, setShowTimeCapacityDayGraph] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    // transportType, cargoType, warehouse, date, time, email
    const [selectionState, setSelectionState] = useState("transportType")
    const [confirmedTimeslot, setConfirmedTimeslot] = useState(undefined)


    // Flow:
    // 1. Select a transport type
    // 2. Select a cargo type
    // 3. Select a warehouse
    // 4. Select a date

    useEffect(() => {
        axios.get(
            process.env.REACT_APP_HOST + '/api/cargo/list_types'
        ).then((res) => {
            setTransportTypes(res.data)
        }).catch(errHandler)

        axios.get(
            process.env.REACT_APP_HOST + '/api/warehouse/list'
        ).then((res) => {
            setWarehouses(res.data.filter(wh => wh.active))

            let defaultId = res.data[0].id
            for (const wh of res.data) {
                if (wh.is_default) {
                    defaultId = wh.id
                }
            }
            setWarehouseId(defaultId)
        }).catch(errHandler)
    }, [])

    let findAndSetCargoTypes = (e) => {
        setTransportType(e.target.value)
        for (const transport of transportTypes) {
            if (transport.id === e.target.value) {
                setCargoTypes(transport.cargo_types)
                setCargoType(transport.cargo_types[0].id)
            }
        }
    }

    useEffect(() => {
        if (cargoType && warehouseId) {
            calculateProcessingTime()
        }
    }, [cargoType, warehouseId])

    // useEffect(() => {
    //     if (cargoType && transportType && warehouseId && processingTime) {
    //         getAvailableTimes()
    //     }
    // }, [warehouseId, processingTime])


    let calculateProcessingTime = () => {
        axios.post(
            process.env.REACT_APP_HOST + '/api/cargo/set_type',
            { cargoId: props.cargo.id, cargoTypeId: cargoType},
            { headers: { 'x-access-token': accessToken } }
        ).then(res => {
            axios.post(
                process.env.REACT_APP_HOST + '/api/cargo/processing_time',
                { cargoId: props.cargo.id, warehouseId },
                { headers: { 'x-access-token': accessToken } }
            ).then(res => {
                setProcessingTime(res.data.processing_time_in_minutes)
            }).catch(errHandler)
        }).catch(errHandler)
    }

    // let updateAvailableTimes = () => {
    //     axios.get(
    //         process.env.REACT_APP_HOST + '/api/timeslot/get_possible_timeslots'
    //     ).then((res) => {
    //         console.log(res.data)
    //         setAvailableTimes(res.data.filter(x => x.code !== 'DEFAULT'))
    //     }).catch(errHandler)
    //
    // }

    let confirmTimeslot = (selectedSlotStartTime, selectedSlotLocationId, customEmail) => {
        let startTime = selectedSlotStartTime
        let dockId = selectedSlotLocationId

        // Add processing time to start time date format using momentjs
        let endDate = moment(startTime).add(processingTime, 'minutes').toISOString()

        // alert("Timeslot confirmed! " + startTime + " - " + endDate)

        let data = {
            dockId: dockId,
            cargoId: props.cargo.id,
            startDateTime: startTime,
            endDateTime: endDate,
        }

        if (customEmail) {
            data.email = customEmail
        }

        axios.post(
            process.env.REACT_APP_HOST + '/api/timeslot/confirm',
            data,
            { headers: { 'x-access-token': accessToken } }
        ).then((res) => {
            props.setConfirmedTimeslot(res.data)
            setSelectionState("confirmation")
        }).catch(errHandler)
    }

    // Confirmation modal



    // Set icon
    let iconSwitch = (param) => {
        switch(param) {
            case 'van':
                return laVan;
            case 'truck':
                return laTruck;
            case 'container':
                return laContainer;  
            default:
                return laTruck;
        }
      }

    let content = null
    switch (selectionState) {
        case "transportType":
            content = <>
                <Col className="select-type-buttons flex-column">
                    <div className="w-100 mb-2 mt-0">
                        <button className="btn btn-primary" onClick={() => props.goback()}>&lt;</button>
                    </div>
                    <div className="d-flex flex-row">
                        { transportTypes.sort((a, b) => {  
                                return a.id >= b.id
                                ? 1
                                : -1
                        }).map((x, i) => {
                            return (
                                <div
                                    className="transport-type-button type-button"
                                    key={i}
                                    onClick={() => {
                                        setTransportType(x)
                                        findAndSetCargoTypes({target: {value: x.id}})
                                        setSelectionState("cargoType")
                                    }}
                                >
                                    {/* <div className="transport-type-icon">
                                        <FontAwesomeIcon icon={"fa-solid fa-" + x.icon_name} size="2x"/>
                                    </div> */}
                                   
                                   <div className="transport-type-icon">
                                                    <img src={iconSwitch(x.icon_name)} alt="" />
                                                </div>
                                            
                                                <div>
                                                    <Badge bg="secondary">
                                                        <h7>
                                                            {x.description}
                                                        </h7>
                                                    </Badge> 
                                                </div>
                                          
                                    
                                    {/* <div className="transport-type-code">{x.code}</div> */}
                                </div>
                            )
                        })}
                    </div>
                </Col>
            </>
            break;
        case "cargoType":
            content = <>
                <Col className="select-type-buttons flex-column">
                    <div className="w-100 mb-2">
                        <button className="go-back-button btn btn-primary" onClick={() => {setSelectionState("transportType")}}>&lt;</button>
                    </div>
                    <div className="d-flex flex-row">
                        { cargoTypes.sort((a, b) => {  
                                return a.id >= b.id
                                ? 1
                                : -1
                        }).map((x, i) => {
                        return (
                            <div
                                className="cargo-type-button type-button"
                                key={i}
                                onClick={() => {
                                    setCargoType(x.id)
                                    setSelectionState("warehouse")
                                }}
                            >
                                {x.code}
                                {x.description}
                            </div>
                        )
                    })}
                    </div>
                </Col>
            </>
            break;
        case "warehouse": // TODO: Only enable if more than one warehouse
            if (warehouses.length === 1) {
                setWarehouseId(warehouses[0].id)
                setSelectionState("date")
            }

            content = <>
                <Col className="select-type-buttons flex-column">
                <div className="w-100 mb-2">
                    <button className="go-back-button btn btn-primary" onClick={() => {setSelectionState("cargoType")}}>&lt;</button>
                </div>
                <div className="d-flex flex-row">
                    { warehouses.map((x, i) => {
                    return (
                        <div
                            className="warehouse-button type-button"
                            key={i}
                            onClick={() => {
                                setWarehouseId(x.id)
                                setSelectionState("date")
                            }}
                        >
                            {x.code}
                        </div>
                    )
                })}
                </div>
                </Col>
            </>
            break;
        case "date": // TODO: Filter out unavailable dates?
            content = <SelectTimeslot
                goback={() => warehouses.length > 1 ? setSelectionState("warehouse") : setSelectionState("cargoType")}
                warehouseId={warehouseId}
                cargoId={props.cargo.id}
                processingTime={processingTime}
                confirmTimeslot={confirmTimeslot} />
            break;
        default:
            content = <div>
                <h1> Error </h1>
            </div>
            break;
    }

    return (
        <Row>
            {
                content
            }
        </Row>
    )
};

TabletSlotSelection.propTypes = {

};

export default TabletSlotSelection;