import React, {useState} from 'react';
import PropTypes from 'prop-types';

const TimeEditor = props => {
    const { value, onUpdate, ...rest } = props;
    let [time, setTime] = useState(value)

    let dateString = new Date().toISOString().substring(0, 11)

    return (
        <div className='config-time-editor'>
            <input type='time' onChange={(e) => setTime(e.target.value)}/>
            <button
                key="submit"
                className="btn btn-primary m-1"
                onClick={ () => onUpdate(new Date(dateString + time).toISOString().substring(11, 24)) }
            >
                Save
            </button>
            {props.allowNull && <button
                key="submit"
                className="btn btn-secondary m-1"
                onClick={ () => onUpdate(null) }
                >Deactivate</button>
            }
        </div>
    )
};

TimeEditor.propTypes = {

};

export default TimeEditor;